import { reactive, ref, toRef } from 'vue'
import useApis from '../../../../hooks/useApis'



export const getDefaultPrePayUnPayParams = () => ({
  cardBin: '',
  routeId: '',
  cardLastFour: '',
  cardNumber: '',
  countryCode: '',
  expireMonth: '',
  expireYear: '',
  cvv: '',
  cpfNumber: '',
  cardHolderName: '',
  rememberCard: 0,
  paymentCode: '',
  installments: 0,
  cardType: '',
  installmentsInfo: {},
  isSelectedInstallment: true,
  isHadBinDiscount: false,
  cardBinDiscountDesc: '',
  autoBindCard: '', // 用户未主动选择了记住卡号
  challengeWindowSize: 'fullPage',
  sessionId: '', // ddc sessionId
  // isShowCardBinDiscountDescInPriceDetail: false,
})

export const getDefaultPrePayCheckoutInfo = () => ({
  orderAttributesList: [],
  orderCurrency: '',
  orderUsdAmount: '',
  orderAmount: '',
  shipCountry: '',
  shipAddressId: '', // 用于信息对比
  virtualOrderScene: null,
  routeCode: '',
  onlinePayDiscountInfo: {},
  cardBinDiscountInfo: {},
  mallCaculateInfo: {},
  bin_discount_list: [],
  bin_discount_info: {},
  isAutoRenewal: false, // TODO 是否选择了自动续费产品包
  taxNumber: '', // 税号，用于自动填充
  billno: '', // 订单号，用于单后场景处理
})

export const usePrePayment = () => {
  const { getPaymentPageLogoApi } = useApis()

  const prePayState = reactive({
    prePayCheckoutInfo: { ...getDefaultPrePayCheckoutInfo() },
    prePayUnPayParams: { ...getDefaultPrePayUnPayParams() },
    cardBinFreezeParams: {
      billno: '',
      bin: '', // 新卡取用户输入的卡号前八位，卡token取接口返回的bin
      payment_code_unique: '',
      v_tips: 0, // 前端展示了卡bin优惠信息，冻结失败则需要弹窗提示
    },
    pageReadyTime: 0, // 页面加载完成时间
    orderInfo: {} as any, // 订单信息
    prePayFailGuide: {
      visible: false,
      failText: '',
      type: '',
      hasGuide: true,
      closeCb: () => {},
      confirmCb: () => {},
    },
  })

  const paymentPageLogo = ref([] as any)



  const formatPrePayCheckoutInfo = ({ checkoutInfo, selectedPayment }) => {
    const addressInfo = checkoutInfo?.addressInfo || {}

    let orderAttributesList = [] // 订单主体id+业务模式集合 需去重
    let orderCurrency = '' // 下单币种
    let orderUsdAmount = '' // 美金币种应付金额
    let orderAmount = '' // 下单币种应付金额	计算价格最终的实付金额（扣除券、积分、礼品卡、钱包等虚拟资产和活动的整车价格）
    let shipCountry = '' // 下单页的地址收货国家
    let shipAddressId = '' // 下单页的地址id
    const virtualOrderScene = checkoutInfo.virtualOrderScene || '' // 虚拟商品（付费会员or超省） 1-单独购 2-随单购 不传或其他值-代表无虚拟商品

    orderUsdAmount = checkoutInfo.totalPriceInfo?.usdAmount || 0
    orderAmount = checkoutInfo.totalPriceInfo?.amount || 0
    orderCurrency = checkoutInfo?.orderCurrencyInfo?.code || ''
    shipCountry = addressInfo?.countryCode || ''
    shipAddressId = addressInfo?.addressId || ''
    try {
      orderAttributesList = (JSON.parse(checkoutInfo?.channelSession || {})?.orderDetails || [])
    } catch (error) {
      console.error('formatPrePayCheckoutInfo error', error)
    }

    return {
      orderAttributesList,
      orderCurrency,
      orderUsdAmount,
      orderAmount,
      shipCountry,
      shipAddressId,
      virtualOrderScene,
      routeCode: selectedPayment.code,
      taxNumber: addressInfo?.taxNumber || '',
    }
  }

  const fetchPreInfo = async ({ paymentCode }: { paymentCode?: string } = {}) => {
    try {
      const [paymentPageLogoResult] = await Promise.allSettled([
        getPaymentPageLogoApi({
          payment_code: paymentCode || '',
          page_type: 4,
        }),
      ])
      // debuggerLog('fetchPreInfo===instamentDataResult===', instamentDataResult)
      // const instamentData = instamentDataResult.status === 'fulfilled' ? instamentDataResult.value : null
      // const { recommend_installments } = instamentData || {}
      // prePayStore.updateState('recommendInstallments', recommend_installments)
      // prePayStore.updateState?.('installmentResInfo', instamentData || {})
      const res = paymentPageLogoResult.status === 'fulfilled' ? paymentPageLogoResult.value : null
      // updateState('installmentList', instamentData?.installments || [])
      // updateState('initInstallmentList', instamentData?.installments || [])
      paymentPageLogo.value = res?.info?.logoList || []
      // console.log('fetchPreInfo===res===', res)
      // return { instamentData: instamentData?.installments || [], paymentPageLogo }

      return { paymentPageLogo: res?.info?.logoList }
    } catch (error) {
      console.error('fetchPreInfo error', error)
    }
  }


  const updatePrePayCheckoutInfo = (data) => {
    prePayState.prePayCheckoutInfo = Object.assign(prePayState.prePayCheckoutInfo, data)
  }

  const mergePrePayUnPayParams = (data) => {
    prePayState.prePayUnPayParams = Object.assign(prePayState.prePayUnPayParams, data)
  }

  const mergeState = (key, payload) => {
    if (prePayState[key]) {
      prePayState[key] = Object.assign(prePayState[key], payload)
    }
  }
  // const prePayForCheckoutValidator = ({
  //   validator = () => {},
  // }) => {
  //   const { result, failReasonResults } = validator?.()
  //   return {
  //     validateResult: !!result,
  //     prePayUnPayParams: prePayState.prePayUnPayParams,
  //     freezeParamsStr: JSON.stringify(prePayState.cardBinFreezeParams || {}),
  //     failReasonResults: failReasonResults || [],
  //   }
  // }

  // const handlePrePayForCheckout = ({ checkOnly = false } = {}) => {
  //   const formValid = prePayModalRef.value?.formValidator?.()
  //   let installmentValid = true
  //   if (prePayStore.prePayCheckoutInfo.routeCode === 'routepay-cardinstallment') {
  //     installmentValid = prePayModalRef?.value?.installmentValidator?.()
  //   }
  //   let isSelectedInstallment = true
  //   if (formValid.result && !installmentValid) {
  //     isSelectedInstallment = false
  //     if (!checkOnly) {
  //       Toast(paymentsStore.language?.SHEIN_KEY_PWA_24897)
  //       prePayModalRef.value.scrollToInstallment()
  //     }
  //   }
  //   prePayStore?.mergeState('prePayUnPayParams', {
  //     ...prePayStore?.prePayUnPayParams,
  //     isSelectedInstallment,
  //   })
  //   if (formValid.result && installmentValid) {
  //     // prePayStore.visiblePrePay = false
  //     if (prePayStore.prePayCheckoutInfo?.billno) {
  //       prePayStore.updateState('cardBinFreezeParams', {
  //         billno: prePayStore.prePayCheckoutInfo.billno,
  //         bin: prePayStore.prePayUnPayParams?.cardNumber?.substring?.(0, 8) || prePayStore.prePayUnPayParams?.cardBin || '',
  //         payment_code_unique: prePayStore.prePayCheckoutInfo?.routeCode,
  //         v_tips: shouldShowBinDiscountFreezeFailTips.value ? 1 : 0,
  //       })
  //     }
  //     return {
  //       validateResult: true,
  //       prePayUnPayParams: prePayStore.prePayUnPayParams,
  //       freezeParamsStr: JSON.stringify(prePayStore.cardBinFreezeParams || {}),
  //       failReasonResults: [],
  //     }
  //   } else {
  //     return {
  //       validateResult: false,
  //       prePayUnPayParams: prePayStore.prePayUnPayParams,
  //       failReasonResults: formValid.failReasonResults,
  //     }
  //   }
  // }

  return {
    prePayFailGuide: toRef(prePayState, 'prePayFailGuide'),
    prePayState,
    paymentPageLogo,
    formatPrePayCheckoutInfo,
    updatePrePayCheckoutInfo,
    mergePrePayUnPayParams,
    resetPrePayState: () => {
      updatePrePayCheckoutInfo(getDefaultPrePayCheckoutInfo())
      mergePrePayUnPayParams(getDefaultPrePayUnPayParams())
    },
    fetchPreInfo,
    mergeState,
  }
}
