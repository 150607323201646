<template>
  <SLazyMount>
    <SDrawer
      v-model:visible="capitecVisible"
      class="mbway-phone-dialog"
      :type="'multi'"
      :append-to-body="true"
      @close="adyenMbwayClose"
    >
      <p class="tip">
        {{ language.SHEIN_KEY_PWA_20119 }}
      </p>
      <div :class="['input-row', mbwayInfo.isFocus ? 'focus-input' : '', mbwayInfo.errTip ? 'err-input' : '']">
        <div class="input-code">
          <SInput
            v-model="displayPhoneCode"
            :label="''"
            @focus="mbwayInputFocus"
            @blur="mbwayInfo.isFocus=false"
          />
        </div>
        <div class="input-phone">
          <SInput
            v-model="mbwayInfo.phone"
            :label="''"
            :clearable="true"
            @focus="mbwayInputFocus"
            @blur="mbwayInfo.isFocus=false"
          />
        </div>
      </div>
      <p
        v-if="mbwayInfo.errTip"
        class="err-tip"
      >
        {{ mbwayInfo.errTip }}
      </p>
      <template #footer>
        <SButton
          class="confirm-btn"
          :width="'100%'"
          :type="['primary']"
          @click="confirmMbwayPhone"
        >
          {{ language.SHEIN_KEY_PWA_14896 }}
        </SButton>
      </template>
    </SDrawer>
  </SLazyMount>
</template>

<script setup lang="ts">
import { SInput } from '@shein-aidc/sui-input/mobile'
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import { reactive, watch, computed, inject } from 'vue'
import useCapitecDialog from '../../../hooks/useCapitecDialog'
import { usePaymentsStore } from '../../../hooks/usePayments'
import { AS_PayToolkit } from '../../../types'
const { triggerNotice } = inject('analysisInstance') as AS_PayToolkit.AnalysisInstance

interface CapitecDialogProps {
  language: Record<string, string>;
  collectionInfo: {
    pay_phone: string;
    pay_email: string;
  };
}

const paymentStore = usePaymentsStore()

const emits = defineEmits(['dialogClose', 'submit', 'pay-now'])

const props = withDefaults(defineProps<CapitecDialogProps>(), {
  language: () => ({}),
  collectionInfo: () => ({
    pay_phone: '',
    pay_email: '',
  }),
})

const { capitecVisible, dialogConfig, addressInfo, checkAliasApi, closeCapitecDialog } = useCapitecDialog()

const mbwayInfo = reactive({
  code: '',
  phone: '',
  isFocus: false,
  errTip: '',
})
const displayPhoneCode = computed({
  get: () => {
    return mbwayInfo.code ? '+' + mbwayInfo.code : ''
  },
  set: (val) => {
    const code = val.includes('+') ? val.split('+')[1] : val
    mbwayInfo.code = code || ''
  },
})

const handleClose = () => {
  emits('dialogClose')
  dialogConfig.value.handleCancel?.()
}

const mbwayInputFocus = () => {
  mbwayInfo.isFocus = true
  mbwayInfo.errTip = ''
}

const adyenMbwayClose = () => {
  paymentStore?.paymentEventBus?.paymentValidSendBi?.emit({
      result_reason: '1_7',
  })
  handleClose()
}

const confirmMbwayPhone = async () => {
  const data = {
    area_abbr: dialogConfig.value.countryCode || '',
    area_code: parseInt(mbwayInfo.code),
    alias: mbwayInfo.phone,
    alias_type: 2,
  }
  const res = await checkAliasApi(data)
  // console.log('confirmMbwayPhone===', res)
  if (res?.code != '0') {
    mbwayInfo.errTip = props.language.SHEIN_KEY_PWA_20120
    return
  }
  const collectInfo = {
    // fix adyen-mbway收集是区号+手机号
    phone: displayPhoneCode.value + mbwayInfo.phone,
    // 国际电话区号
    phoneCountryCode: mbwayInfo.code,
    // 本地电话号码
    localPhone: mbwayInfo.phone,
  }
  closeCapitecDialog()
  if (typeof dialogConfig.value.handleConfirm === 'function') {
    dialogConfig.value.handleConfirm(collectInfo)
  } else {
    emits('submit', {
      paymentCode: dialogConfig.value.paymentCode,
      collectInfo,
    })
  }
  if (dialogConfig.value.needContinuePay) {
    emits('pay-now')
  }
}

const init = () => {
  mbwayInfo.phone = props.collectionInfo.pay_phone || addressInfo.value?.tel || ''
  mbwayInfo.errTip = ''
  mbwayInfo.isFocus = false

  const phoneCode = dialogConfig.value?.phoneCountryNum?.split('+')?.[1] || ''

  mbwayInfo.code = phoneCode || ''

  triggerNotice({
    id: 'expose_popup_telephonenumber.comp_capitec_drawer',
  })
}

watch(capitecVisible, (val) => {
  if (val) {
    init()
  }
}, { immediate: true })


</script>

<style lang="less" scoped>
.mbway-phone-dialog {
	.tip {
		text-align: center;
		font-size: 14/37.5rem;
		font-weight: bold;
		margin: 0 0.7333rem;
		margin-top: 0.32rem;
		margin-bottom: 0.42666rem;
	}
	.input-row {
		display: flex;
		margin: 0 0.32rem;
		justify-content: flex-start;
		border: 1px solid #ccc;

		/deep/.sui-input__box {
			margin-top: 0;
		}
		.input-code {
			width: 1.6rem;
			border-right: 1px solid #ccc;
		}
		.input-phone {
			width: 100%;
		}

    /deep/.sui-input:after {
      display: none;
    }


    /deep/.sui-input__label {
      display: none;
    }

		/deep/.sui-input__clear {
			margin-top: 0;
			vertical-align: middle;
		}
	}

  .focus-input {
    border: 1px solid #222;
    .input-code {
      border-right: 1px solid #222;
    }
  }

  .err-input {
    border: 1px solid #C44A01;
    .input-code {
      border-right: 1px solid #C44A01;
    }
  }
	.err-tip {
		color: #BF4123;
		margin: 0 0.32rem;
		margin-top: 0.10666rem;
		text-align: left;
	}
	.confirm-btn {
		margin: 0.48rem 0 0.32rem;
	}
}
</style>
