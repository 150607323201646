// import * as Vue from 'vue'
import { debuggerLog } from '../utils'
export interface StoreAction<T = string> {
  mergeState: (type: T, payload: any) => void
  updateState: (type: T, payload: any) => void
}

/**
 * @description: useStoreAction
 * @param {source} source
 * @return {StoreAction}
 */
export const useStoreAction = <T>(source): StoreAction<T> => {
  const mergeState = (type: T, payload) => {
    // Object.assign(source[type], payload)
    try {
      debuggerLog('mergeState>>>>>>>>', type, payload, source[type])
      debuggerLog('rootStore>>>>', source)
      Object.assign(source[type], payload)
      // if (!Vue.set) {
      //   Object.assign(source[type], payload)
      // } else {
      //   // Vue2.x
      //   Vue.set?.(source, type as any, typeof payload === 'object' ? { ...source[type], ...payload } : payload)
      // }
    } catch (e) {
      console.log(e)
    }
  }

  const updateState = (type: T, payload) => {
    // source[type] = payload
    try {
      source[type] = payload
      // if (!Vue.set) {
      //   source[type] = payload
      // } else {
      //   // Vue2.x
      //   Vue.set?.(source, type as any, payload)
      // }
      debuggerLog('updateState>>>>>>>>', type, payload, source[type])
      debuggerLog('rootStore>>>>', source)
    } catch (e) {
      console.log(e)
    }
  }

  return {
    mergeState,
    updateState,
  }
}
