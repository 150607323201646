import { getLocalStorage, setLocalStorage } from '@shein/common-function'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { ref, computed, onMounted, watch } from 'vue'
import { type PaymentsStore } from './usePayments'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import { debuggerLog } from '../utils'

export function useUpgradeAppTips({ props, paymentsStore }: { props: any; paymentsStore: PaymentsStore }) {
  const appConfigs = useAppConfigs()
  const language = computed(() => paymentsStore.language)

  const CACHE_KEY = 'SHOWED_FPX_TIPS_MEMBER_ID_LIST'

  const upgradeAppTipsResolve = ref<(val: unknown) => void >(() => ({}))
  const upgradeAppTipsDialogVisible = ref(false)
  const upgradeAppTipsCountDown = ref(5)
  let upgradeAppTipsTimer: any = null
  const showedUpgradeAppTipsList = ref<string[]>([])

  const upgradeAppBankCodes = computed(() => props?.paymentInfo?.fpxShowUpgradationBank || [])

  const _getCacheKey = (paymentMethod = '') => {
    const memberId = appConfigs?.$getters.memberId || ''
    return `${memberId}_${paymentMethod}`
  }

  const upgradeAppTipsDialogProps = computed(() => {
    const project = appConfigs?.$envs.project
    return {
      visible: upgradeAppTipsDialogVisible.value,
      tips: project === 'pc' ? language.value.SHEIN_KEY_PC_27372 : language.value.SHEIN_KEY_PWA_26428,
      confirmText: `${project === 'pc' ? language.value.SHEIN_KEY_PC_27383 : language.value.SHEIN_KEY_PWA_26440}${upgradeAppTipsCountDown.value > 0 ? `（${upgradeAppTipsCountDown.value}S）` : ''}`,
    }
  })

  const checkShowUpgradeAppTips = (paymentMethod = '') => {
    const cacheKey = _getCacheKey(paymentMethod)
    debuggerLog('checkShowUpgradeAppTips>>>>', cacheKey, showedUpgradeAppTipsList.value)
    /**
     * 1. abt开启
     * 2. 在阿波罗配置的可以弹窗提示的支付方式内
     * 3. 没有缓存过
     */
    return upgradeAppBankCodes.value.includes(paymentMethod) &&
    !showedUpgradeAppTipsList.value.includes(cacheKey)
  }

  const showUpgradeAppTips = (paymentMethod = '') => {
    upgradeAppTipsDialogVisible.value = true
    const cacheKey = _getCacheKey(paymentMethod)
    showedUpgradeAppTipsList.value.push(cacheKey)
    setLocalStorage({
      key: CACHE_KEY,
      value: showedUpgradeAppTipsList.value,
    })
    return new Promise((resolve) => {
      upgradeAppTipsResolve.value = resolve
      upgradeAppTipsCountDown.value = 5
      upgradeAppTipsTimer = setInterval(() => {
        upgradeAppTipsCountDown.value--
        if (upgradeAppTipsCountDown.value === 0) {
          clearInterval(upgradeAppTipsTimer)
          upgradeAppTipsDialogVisible.value = false
          resolve('confirm')
        }
      }, 1000)
    })
  }

  const handleUpgradeAppTipsConfirm = () => {
    clearInterval(upgradeAppTipsTimer)
    upgradeAppTipsDialogVisible.value = false
    upgradeAppTipsResolve.value('confirm')
  }

  const handleUpgradeAppTipsCancel = () => {
    clearInterval(upgradeAppTipsTimer)
    upgradeAppTipsDialogVisible.value = false
    upgradeAppTipsResolve.value('cancel')
  }

  const validateByPay = async (): Promise<Trade_PayToolKit.ValidateByPayType> => {
    const isCheck = checkShowUpgradeAppTips(paymentsStore.selectedPayment?.code)
    if (!isCheck) {
      return {
        validateResult: true,
        scene: 'payment_fpx_upgrade_app',
      }
    }

    const status = await showUpgradeAppTips(paymentsStore.selectedPayment?.code)
    if (status === 'cancel') {
      return {
        validateResult: false,
        scene: 'payment_fpx_upgrade_app',
        metric_scene: 'payment_fpx_upgrade_app_cancel',
        saParams: {
          result_reason: '1_6',
        },
      }
    }
    return {
      validateResult: true,
    }
  }

  watch(() => upgradeAppTipsCountDown.value, (val, oldVal) => {
    if (val !== oldVal && val === 0) {
      paymentsStore?.paymentEventBus?.paymentValidSendBi?.emit({
        result_reason: '',
      })
    }
  })

  onMounted(() => {
    showedUpgradeAppTipsList.value = getLocalStorage(CACHE_KEY) || []
  })

  return {
    upgradeAppTipsDialogProps,
    checkShowUpgradeAppTips,
    handleUpgradeAppTipsConfirm,
    handleUpgradeAppTipsCancel,
    validateByPay,
  }
}
