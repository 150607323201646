<template>
  <div class="payment-card-form">
    <CardNumberFormItem
      ref="cardNumberField"
      v-model="formData.cardNumber"
      data-field="cardNumber"
      :label="language.SHEIN_KEY_PWA_25749"
      placeholder="0000 0000 0000 0000"
      :validator="handleCardNumberValidator"
      :card-logo="cardBinRoutingInfo.webLogo || 'https://img.ltwebstatic.com/images3_ccc/2024/06/29/08/1719672962d927dc54aa32ebc6b07a741b6c29c719.png'"
      @change="handleCardNumberChange"
      @blur="handleCardNumberBlur"
      @clear="handleCardNumberClear"
    />
    <ItemSlide :visible="showSlideName">
      <NameFormItem
        ref="cardNameField"
        v-model="formData.cardHolderName"
        data-field="cardHolderName"
        :required="showSlideName"
        :error-message="language.SHEIN_KEY_PWA_25752"
        :placeholder="language.SHEIN_KEY_PWA_25751"
        :label="language.SHEIN_KEY_PWA_25750"
      />
    </ItemSlide>
    <ItemSlide :visible="showSlideCpf">
      <CpfFormItem
        ref="cardCpfField"
        v-model="formData.cpfNumber"
        data-field="cpfNumber"
        :required="showSlideCpf"
        :document-rule="documentConfig.rule"
        :label="documentConfig.title"
        :placeholder="documentConfig.placeholder"
        :show-tips="!!cpfPopConfig.message"
        @click-tips="() => visiblePopupConfig.cpfPop = true"
      />
    </ItemSlide>
    <ItemSlide
      v-if="!showKRAlipayCard"
      :visible="showNormalItems"
    >
      <div class="payment-card-form__horizontal item-underline">
        <ExpireDateFormItem
          ref="expireDateField"
          v-model="formData.expireDate"
          data-field="expireDate"
          class="item-sep-line__right"
          :validator="expireDateValidator"
          :placeholder="`${language.SHEIN_KEY_PWA_25745}/${language.SHEIN_KEY_PWA_25746}`"
          :label="language.SHEIN_KEY_PWA_25744"
          :cancel-text="language.SHEIN_KEY_PWA_24595"
          :confirm-text="language.SHEIN_KEY_PWA_24594"
        />
        <CvvFormItem
          ref="cardCvvField"
          v-model="formData.cvv"
          data-field="cvv"
          placeholder="000"
          :cardType="cardBinRoutingInfo.cardType"
          :error-message="language.SHEIN_KEY_PWA_30131"
          :label="isShowDynamicCvv ? cvvTipsTransLanguage.SHEIN_KEY_PWA_36257 : language.SHEIN_KEY_PWA_25758"
          @click-tips="() => visiblePopupConfig.isShowCvvTip = true"
        />
      </div>
    </ItemSlide>
    <ItemSlide
      v-if="!showNormalItems"
      :visible="showKRAlipayCard"
    >
      <KRCardTypeSelectItem
        v-model="krAlipayCardForm.cardType"
        :label="language.SHEIN_KEY_PWA_29848"
        :select-config="localCardInfoConfig.cardTypeSelectConfig"
        @change="handleCardTypeChange"
      />
      <div class="payment-card-form__horizontal item-underline">
        <!-- 生日收集 -->
        <BirthdayDateFormItem
          v-if="localCardInfoConfig.isShowCollectCardholderBirth"
          ref="birthdayDateField"
          v-model="krAlipayCardForm.birthday"
          data-field="cardHolderBirth"
          class="payment-form-item item-sep-line__right"
          :validator="birthdayDateValidator"
          :required="localCardInfoConfig.isShowCollectCardholderBirth"
          :placeholder="`${language.SHEIN_KEY_PWA_29850}/${language.SHEIN_KEY_PWA_29851}/${language.SHEIN_KEY_PWA_29849}`"
          :label="language.SHEIN_KEY_PWA_29852"
        />
        <!-- 公司企业号 -->
        <BasicFormItem
          v-if="localCardInfoConfig.isShowCollectBusinessNo"
          ref="businessNoField"
          v-model="krAlipayCardForm.businessNo"
          data-field="local_card_businsss_no"
          class="payment-form-item item-sep-line__right"
          :required="localCardInfoConfig.isShowCollectBusinessNo"
          :validator="businessNoValidator"
          :placeholder="language.SHEIN_KEY_PWA_29830"
          :label="language.SHEIN_KEY_PWA_29828"
        />
        <!-- 密码前2位收集 -->
        <BasicFormItem
          v-if="localCardInfoConfig.isShowCollectFirstTwoDigitsOfPassword && !localCardInfoConfig.isKREnterprise"
          ref="personPasswordField"
          key="local_card_person_password"
          v-model="krAlipayCardForm.personPassword"
          data-field="local_card_person_password"
          :maxlength="2"
          :required="true"
          :validator="passwordValidator"
          :placeholder="language.SHEIN_KEY_PWA_29829"
          :label="language.SHEIN_KEY_PWA_29826"
        />
        <BasicFormItem
          v-if="localCardInfoConfig.isShowCollectFirstTwoDigitsOfPassword && localCardInfoConfig.isKREnterprise"
          ref="companyPasswordField"
          key="local_card_company_password"
          v-model="krAlipayCardForm.companyPassword"
          data-field="local_card_company_password"
          :maxlength="2"
          :required="true"
          :validator="passwordValidator"
          :placeholder="language.SHEIN_KEY_PWA_29829"
          :label="language.SHEIN_KEY_PWA_29826"
        />
      </div>
      <ExpireDateFormItem
        ref="expireDateField"
        v-model="formData.expireDate"
        data-field="expireDate"
        :validator="expireDateValidator"
        :placeholder="`${language.SHEIN_KEY_PWA_25745}/${language.SHEIN_KEY_PWA_25746}`"
        :label="language.SHEIN_KEY_PWA_25744"
        :cancel-text="language.SHEIN_KEY_PWA_24595"
        :confirm-text="language.SHEIN_KEY_PWA_24594"
      />

      <CvvFormItem
        v-if="localCardInfoConfig.isShowCvv"
        ref="alipayCardCvvField"
        v-model="formData.cvv"
        data-field="cvv"
        placeholder="000"
        :cardType="cardBinRoutingInfo.cardType"
        :error-message="language.SHEIN_KEY_PWA_30131"
        :label="isShowDynamicCvv ? cvvTipsTransLanguage.SHEIN_KEY_PWA_36257 : language.SHEIN_KEY_PWA_25758"
        @click-tips="() => visiblePopupConfig.isShowCvvTip = true"
      />
    </ItemSlide>
    <!-- 动态cvv -->
    <div
      v-if="cvvTipsTransLanguage.SHEIN_KEY_PWA_36233 && isShowDynamicCvv"
      class="dynamic-cvv-tips"
      v-html="cvvTipsTransLanguage.SHEIN_KEY_PWA_36233"></div>
    <RememberCard
      v-model="formData.rememberCard"
      :disabled="isForbiddenRememberCard"
      :show-tips="!isForbiddenRememberCard"
      :text="language.SHEIN_KEY_PWA_32147"
      @click="handleRememberCardClick"
      @click-tips="() => visiblePopupConfig.rememberCardTips = true"
    />

    <BSrcConsent
      v-if="isShowC2pInfo"
      :language-info="language"
      :now-card-logo="nowC2pCardLogo"
      :card-type="nowC2pCardType"
      :init-c2p-src-content-info="initC2pSrcContentInfo"
      @handlerClick="handleC2pConsentInfo"
    />

    <SLazyMount>
      <RememberCardTips
        v-model:visible="visiblePopupConfig.rememberCardTips"
        :language="language"
      />
    </SLazyMount>
    <SLazyMount>
      <ForbiddenRememberCardTips
        v-model:visible="visiblePopupConfig.forbiddenRememberCardTips"
        :language="language"
      />
    </SLazyMount>
    <SLazyMount>
      <RememberCardPolicyTips
        v-model:visible="visiblePopupConfig.rememberCardPolicyTips"
        v-expose="{ id: 'expose_popup_rememberthiscard.comp_pre_pay' }"
        :language="language"
        @actively-close="handleCloseRememberCardPolicyTips"
        @confirm="handleSelectedRememberCard"
      />
    </SLazyMount>
    <SLazyMount>
      <CpfPop
        v-model:visible="visiblePopupConfig.cpfPop"
        :config="cpfPopConfig"
      />
    </SLazyMount>
    <!-- cvv提示弹窗 -->
    <SLazyMount>
      <CvvTipsPopup
        v-model:visible="visiblePopupConfig.isShowCvvTip"
        :language="cvvTipsTransLanguage"
        :is-show-dynamic-cvv="isShowDynamicCvv"
      />
    </SLazyMount>
  </div>
</template>

<script setup>
import { computed, reactive, ref, watch, defineAsyncComponent } from 'vue'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import {
  CardNumberFormItem,
  CvvFormItem,
  RememberCard,
  ExpireDateFormItem,
  NameFormItem,
  CpfFormItem,
  ItemSlide,
  KRCardTypeSelectItem,
  BirthdayDateFormItem,
  BasicFormItem,
  getCardNumberRules,
  cardNumberValidator,
} from '@shein-aidc/bs-pay-card-form-mobile'
import {
  BSrcConsent,
} from '@shein-aidc/bs-click-to-pay-mobile'
import { useAnalysis } from '../../../../common/analysisSource'

const RememberCardTips = defineAsyncComponent(() => import('./RememberCardTips.vue'))
const ForbiddenRememberCardTips = defineAsyncComponent(() => import('./ForbiddenRememberCardTips.vue'))
const RememberCardPolicyTips = defineAsyncComponent(() => import('./RememberCardPolicyTips.vue'))
const CpfPop = defineAsyncComponent(() => import('./CpfPop.vue'))
const CvvTipsPopup = defineAsyncComponent(() => import('../../pre-pay/CvvTipsPopup.vue'))

function debuggerLog(...args) {
  if (typeof window === 'undefined') return
  try {
    const enableDebugLog = sessionStorage?.getItem?.('__bs__enable_debug_log')
    if (!enableDebugLog) return
    console.info(
      '%cPre Pay Libs Debug Info:',
      'background:#35495e ; padding: 3px 3px; border-radius: 3px;  color: #fff',
      ...args,
      '\n',
    )
  } catch (e) {
    console.log(...args)
  }
}

const formatCardHolderBirthday = (birthday = '') => {
  const [month = '', day = '', year = ''] = birthday.split('/')
  if (!month || !day || !year) return ''
  return `${year}-${month}-${day}`
}

const { triggerNotice } = useAnalysis()

const emits = defineEmits(['bin-change', 'handle-c2p-consent-info'])
const props = defineProps({
  language: {
    type: Object,
    default: () => ({}),
  },
  cardBinRoutingInfo: {
    type: Object,
    default: () => ({}),
  },
  binDiscountCardTokenInfo: {
    type: Object,
    default: () => ({}),
  },
  countryCode: {
    type: String,
    default: '',
  },
  prePayCheckoutInfo: {
    type: Object,
    default: () => ({}),
  },
  c2pInfo: {
    type: Object,
    default: () => ({}),
  },
  abtInfo: {
    type: Object,
    default: () => ({}),
  },
})

const defaultFormData = {
  cardNumber: '',
  cardHolder: '',
  expireDate: '',
  cvv: '',
  cpfNumber: '',
  cardHolderName: '',
  bin: '',
  rememberCard: 0,
}

const defaultKrAlipayCardForm = {
  cardType: 1,
  personPassword: '',
  companyPassword: '',
  businessNo: '',
  birthday: '',
}

const krAlipayCardForm = reactive({ ...defaultKrAlipayCardForm })

const cpfDisplayConfigs = (language) => ({
  // 巴西
  'BR': {
    placeholder: language?.SHEIN_KEY_PWA_25753,
  },
  // 阿根廷
  'AR': {
    placeholder: language?.SHEIN_KEY_PWA_25755,
  },
  // CO
  'CO': {
    placeholder: language?.SHEIN_KEY_PWA_25754,
  },
  // ZA
  'ZA': {
    placeholder: language?.SHEIN_KEY_PWA_25756,
  },
})

const visiblePopupConfig = reactive({
  isShowCvvTip: false,
  rememberCardTips: false,
  forbiddenRememberCardTips: false,
  rememberCardPolicyTips: false,
  cpfPop: false,
})

const cpfPopConfig = reactive({
  message: '',
  imgSrc: '',
})

const cardNumberField = ref()
const cardNameField = ref()
const cardCpfField = ref()
const expireDateField = ref()
const cardCvvField = ref()

const birthdayDateField = ref()
const alipayCardCvvField = ref()
const businessNoField = ref()
const personPasswordField = ref()
const companyPasswordField = ref()

const formFieldRefs = computed(() => {
  if (showKRAlipayCard.value) {
    return krAlipayCardFormFieldRefs.value
  }
  return [
    cardNumberField,
    cardNameField,
    cardCpfField,
    expireDateField,
    cardCvvField,
  ]
})

const krAlipayCardFormFieldRefs = computed(() => {
  const {
    isKREnterprise,
    isShowCollectBusinessNo,
    isShowCollectFirstTwoDigitsOfPassword,
    isShowCollectCardholderBirth,
    isShowCvv,
  } = localCardInfoConfig.value
  return [
    cardNumberField,
    isShowCollectFirstTwoDigitsOfPassword && isKREnterprise ? companyPasswordField : null,
    isShowCollectFirstTwoDigitsOfPassword && !isKREnterprise ? personPasswordField : null,
    isShowCollectBusinessNo ? businessNoField : null,
    isShowCollectCardholderBirth ? birthdayDateField : null,
    expireDateField,
    isShowCvv ? alipayCardCvvField : null,
  ]
})

const isForbiddenRememberCard = ref(false)
const isDefaultRememberCard = ref(false)

const formData = reactive({ ...defaultFormData })

const localCardInfoConfig = computed(() => {
  const { localCardInfo = {}, needCvv } = props?.cardBinRoutingInfo || {}
  return {
    // 公司号
    isKREnterprise: krAlipayCardForm.cardType === 2,
    // 是否收集公司企业号
    isShowCollectBusinessNo: localCardInfo?.collectBusinessNo && krAlipayCardForm.cardType === 2,
    // 是否收集持卡人密码前两位
    isShowCollectFirstTwoDigitsOfPassword: localCardInfo?.collectFirstTwoDigitsOfPassword,
    // 是否收集持卡人生日
    isShowCollectCardholderBirth: localCardInfo?.collectCardholderBirth && krAlipayCardForm.cardType === 1,
    // 是否显示cvv
    isShowCvv: needCvv == 1,
    cardTypeSelectConfig: [
      { label: 1, text: props.language.SHEIN_KEY_PWA_29846 },
      { label: 2, text: props.language.SHEIN_KEY_PWA_29847 },
    ],
  }
})

const documentConfig = computed(() => {
  const cpfConfig = cpfDisplayConfigs(props.language)?.[props.countryCode] || {}
  // const cpfConfig = {
  //   placeholder: '测试placeholder',
  //   rule: '测试rule',
  // }
  return {
    title: props?.cardBinRoutingInfo?.documentTitle || '',
    placeholder: cpfConfig.placeholder || '',
    rule: props?.cardBinRoutingInfo?.documentRule || '',
  }
})


const showSlideName = computed({
  get () {
    return props?.cardBinRoutingInfo?.isCardHoldName || false
  },
  set () {
  },
})

const showSlideCpf = computed({
  get () {
    return props?.cardBinRoutingInfo?.isDocument || false
  },
  set () {
  },
})

const nowC2pCardLogo = computed(() => {
  if ([1, 2].includes(props?.cardBinRoutingInfo?.coBrandLevel || '')) {
    return ''
  }

  return props?.cardBinRoutingInfo?.webLogo || ''
})

const nowC2pCardType = computed(() => {
  return props?.cardBinRoutingInfo?.cardType || ''
})

const isShowC2pInfo = computed(() => {
  return (
    props.c2pInfo?.isCanUseC2pToken &&
    props.c2pInfo?.isInitC2pSuccess &&
    props.c2pInfo?.c2pAvailableCardBrands?.some(
      item =>
        (props?.cardBinRoutingInfo?.cardType || '')?.toLowerCase() === item?.toLowerCase(),
    )
  )
})

const initC2pSrcContentInfo = computed(() => {
  return props.c2pInfo?.initC2pSrcContentInfo || {}
})
const showKRAlipayCard = computed({
  get () {
    const { localCardInfo, orderCountry } = props?.cardBinRoutingInfo || {}
    return (orderCountry === 'KR' || props.countryCode === 'KR') && !!localCardInfo
  },
  set () {
  },
})

const showNormalItems = computed({
  get () {
    return !showKRAlipayCard.value
  },
  set () {
  },
})

const cvvTipsTransLanguage = computed(() => {
  return {
    SHEIN_KEY_PWA_36256: props.language?.SHEIN_KEY_PWA_36256,
    SHEIN_KEY_PWA_36234: props.language?.SHEIN_KEY_PWA_36234,
    SHEIN_KEY_PWA_36235: props.language?.SHEIN_KEY_PWA_36235,
    SHEIN_KEY_PWA_36236: props.language?.SHEIN_KEY_PWA_36236,
    SHEIN_KEY_PWA_30130: props.language?.SHEIN_KEY_PWA_30130,
    SHEIN_KEY_PWA_33715: props.language?.SHEIN_KEY_PWA_33715,
    SHEIN_KEY_PWA_36233: props.language?.SHEIN_KEY_PWA_36233,
    SHEIN_KEY_PWA_36257: props.language?.SHEIN_KEY_PWA_36257,
  }
})

const isShowDynamicCvv = computed(() => {
  const cardNumber = formData.cardNumber?.replace?.(/\s/g, '') || ''
  const isValidCardBin = cardNumber.length >= 8
  const isShowByAbt = props.abtInfo?.Mxdynamiccvv?.param?.dynamictest == 1
  const isVirtualCardBin = props?.cardBinRoutingInfo?.isVirtualCardBin == 1
  const alipayCardNeedCvv = showKRAlipayCard.value ? localCardInfoConfig.value.isShowCvv : true
  return isShowByAbt && isValidCardBin && isVirtualCardBin && alipayCardNeedCvv
})

watch(() => props.cardBinRoutingInfo, (info) => {
  if (typeof window === 'undefined') return
  isForbiddenRememberCard.value = info?.isRememberCard != null ? !info?.isRememberCard : false
  isDefaultRememberCard.value = info?.defaultRemember != null ? !!info?.defaultRemember : false
  // 已选择记住卡号，但前置路由返回禁止记住卡号时，自动取消记住卡号
  // 未记住卡号，但前置路由返回默认记住卡号时，自动选择记住卡号
  if (formData.rememberCard == 1) {
    formData.rememberCard = isForbiddenRememberCard.value ? 0 : 1
  } else {
    formData.rememberCard = !isForbiddenRememberCard.value && isDefaultRememberCard.value ? 1 : 0
  }

  cpfPopConfig.message = ''
  cpfPopConfig.imgSrc = ''
  // 需要税号时自动填充税号
  if (info?.isDocument) {
    formData.cpfNumber = info?.isDocument ? formData.cpfNumber || props.prePayCheckoutInfo?.taxNumber || '' : ''
    if (props.countryCode === 'ZA' && info?.documentDesc) {
      cpfPopConfig.message = props.language?.SHEIN_KEY_PWA_19531 || ''
      cpfPopConfig.imgSrc = info?.documentDesc?.webDocumentImg || ''
    }
  }
  if (!showSlideName.value) {
    formData.cardHolderName = ''
    cardNameField.value?.reset?.()
  }
  if (!showSlideCpf.value) {
    formData.cpfNumber = ''
    cardCpfField.value?.reset?.()
  }
}, { immediate: true, deep: true })

const handleCardNumberValidator = (cardNumber) => {
  const rules = getCardNumberRules({ ruleMessageConfig: {
    'null': props.language?.SHEIN_KEY_PWA_25761,
    'first_number': props.language?.SHEIN_KEY_PWA_25762,
    'num_of_digits': props.language?.SHEIN_KEY_PWA_25764,
    'luna': props.language?.SHEIN_KEY_PWA_25764,
  } })
  return cardNumberValidator({ cardNumber, rules })
}

const handleCardNumberClear = () => {
  debuggerLog('handleCardNumberClear >>>>>>')
  formData.bin = ''
  emits('bin-change', '')
}

const handleCardNumberBlur = () => {
  debuggerLog('handleCardNumberBlur >>>>>>')
  // const cardNumber = formData.cardNumber
  // if (cardNumber.length < 8) {
  //   handleCardNumberClear()
  // }
}

// const handleCardNumberAllClear = () => {
//   cardNumberField.value?.reset?.()
//   handleCardNumberClear()
// }

const expireDateValidator = ({ selectDate, expireDateValue }) => {
  // result, message, failType
  let errorMessage = ''
  const [month, year] = selectDate || []
  let failType = ''
  if (!month || !year || !expireDateValue) {
    failType = 'null'
    errorMessage = props.language.SHEIN_KEY_PWA_25748
  }

  console.log('expireDateValidator', errorMessage, failType)

  return {
    result: !failType,
    failType: failType,
    failReason: null,
    message: errorMessage,
  }
}

const formValidator = () => {
  let result = true
  let firstNoValidField = null
  const failReasonResults = []
  for(let i = 0; i < formFieldRefs.value.length; i++) {
    const field = formFieldRefs.value?.[i]
    if (field?.value?.validator) {
      const { result: fieldResult, failType, reasonType } = field.value.validator()
      result = result && fieldResult
      if (!fieldResult && reasonType) {
        failReasonResults.push(`${reasonType}:${failType}`)
      }
      if (!fieldResult && !firstNoValidField) {
        firstNoValidField = field.value
      }
    }
  }
  if (firstNoValidField) {
    firstNoValidField?.focus?.()
  }
  let formInfo = {}
  if (result) {
    const cardNumber = formData.cardNumber?.replace?.(/\s/g, '') || ''
    formInfo = {
      cardNumber,
      cpfNumber: formData.cpfNumber || '',
      rememberCard: formData.rememberCard,
      autoBindCard: !formData.rememberCard && !isForbiddenRememberCard.value ? 1 : 0,
      cardHolderName: formData.cardHolderName || '',
      expireYear: formData.expireDate.split('/')[1],
      expireMonth: formData.expireDate.split('/')[0],
      cardLastFour: cardNumber.slice(-4),
      cvv: formData.cvv,
      countryCode: props.countryCode,
      cardHolderBirthday: formatCardHolderBirthday(krAlipayCardForm.birthday) || '',
      cardEnterpriseBusinessNo: krAlipayCardForm.businessNo || '',
      cardPasswordFirstTwoDigit: localCardInfoConfig.value.isKREnterprise ? krAlipayCardForm.companyPassword : krAlipayCardForm.personPassword,
    }
  }

  return {
    result,
    formInfo,
    failReasonResults,
  }
}

const birthdayDateValidator = ({ timeDate }) => {
  let errorMessage = ''
  let failType = ''
  if (!timeDate) {
    failType = 'null'
    errorMessage = props.language.SHEIN_KEY_PWA_29852 || ''
  }

  return {
    result: !failType,
    failType: failType,
    failReason: null,
    message: errorMessage,
  }
}

const passwordValidator = (value = '') => {
  let errorMessage = ''
  const regex = new RegExp(props.cardBinRoutingInfo?.localCardInfo?.firstTwoDigitsOfPasswordRule)
  let isCorrect = regex?.test(value)
  errorMessage = !isCorrect ? props.language.SHEIN_KEY_PWA_29829 : ''
  return {
    result: isCorrect,
    failType: isCorrect ? '' : 'first_two_digits_of_password',
    message: errorMessage,
    reasonType: 'password',
  }
}

const businessNoValidator = (value = '') => {
  let errorMessage = ''
  const regex = new RegExp(props.cardBinRoutingInfo?.localCardInfo?.businessNoRule)
  let isCorrect = regex?.test(value)
  errorMessage = !isCorrect ? props.language.SHEIN_KEY_PWA_29830 : ''
  return {
    result: isCorrect,
    failType: isCorrect ? '' : 'business_no',
    message: errorMessage,
    reasonType: 'business_no',
  }
}

const resetForm = () => {
  for (const key in defaultFormData) {
    formData[key] = defaultFormData[key]
  }
  for (const key in defaultKrAlipayCardForm) {
    krAlipayCardForm[key] = defaultKrAlipayCardForm[key]
  }
  formFieldRefs.value?.forEach?.((field) => {
    if (field?.value?.reset) {
      field.value.reset()
    }
  })
  debuggerLog('resetForm=====', { formData, krAlipayCardForm })
}

const handleRememberCardClick = () => {
  debuggerLog('handleRememberCardClick=====', formData.rememberCard, isForbiddenRememberCard.value)
  if (isForbiddenRememberCard.value) {
    visiblePopupConfig.forbiddenRememberCardTips = true
    triggerNotice({
      id: 'click_remember_thiscard.comp_pre_pay',
      data: {
        is_remember: 2,
      },
    })
    return
  }
  // TODO 待处理
  // if (formData.rememberCard && prePayCheckoutInfo.value?.isAutoRenewal) {
  //   Toast(props.language?.SHEIN_KEY_PWA_31583 || '')
  //   return
  // }
  // 记住卡号 -> 不记住卡号弹出提示
  if (formData.rememberCard && isDefaultRememberCard.value) {
    visiblePopupConfig.rememberCardTips = true
  // 不记住卡号 -> 记住卡号弹出隐私政策提示
  } else if (!formData.rememberCard && !isDefaultRememberCard.value) {
    visiblePopupConfig.rememberCardPolicyTips = true
    return
  }
  formData.rememberCard = formData.rememberCard ? 0 : 1
  triggerNotice({
    id: 'click_remember_thiscard.comp_pre_pay',
    data: {
      is_remember: formData.rememberCard,
    },
  })
}

const handleSelectedRememberCard = () => {
  visiblePopupConfig.rememberCardPolicyTips = false
  formData.rememberCard = 1
  triggerNotice({
    id: 'click_rememberthiscard_ok.comp_pre_pay',
  })
  triggerNotice({
    id: 'click_remember_thiscard.comp_pre_pay',
    data: {
      is_remember: formData.rememberCard,
    },
  })
}

const handleCloseRememberCardPolicyTips = () => {
  visiblePopupConfig.rememberCardPolicyTips = false
  triggerNotice({
    id: 'click_rememberthiscard_close.comp_pre_pay',
  })
  triggerNotice({
    id: 'expose_remembercard_status.comp_pre_pay',
  })
}

const handleCardNumberChange = (cardNumber = '') => {
  const newBin = cardNumber?.slice?.(0, 8) || ''
  if (cardNumber.length < 8) {
    emits('bin-change', newBin)
  }
  if (cardNumber.length >= 8) {
    formData.bin = newBin
    emits('bin-change', newBin)
  } else if (!newBin) {
    formData.bin = ''
    emits('bin-change', '')
  }
}

const handleC2pConsentInfo = consentInfo => {
  emits('handle-c2p-consent-info', consentInfo)
}
const handleCardTypeChange = () => {
  for (const key in defaultKrAlipayCardForm) {
    if (['businessNo', 'birthday'].includes(key)) {
      krAlipayCardForm[key] = defaultKrAlipayCardForm[key]
    }
  }
}

defineExpose({
  formValidator,
  resetForm,
})

</script>

<style lang="less">
.payment-card-form {
  .payment-card-form__horizontal {
    display: flex;
    justify-content: space-between;
    .payment-form-item {
      flex: 1;
      overflow: hidden;
    }
  }
  .item-underline {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: var(--sui_color_gray_light2, #e5e5e5);
    }
  }
  .dynamic-cvv-tips {
    margin: 6*2/75rem 12*2/75rem 8*2/75rem;
    padding: 8*2/75rem;
    background-color: #FFF8EB;
    border-radius: 2*2/75rem;
    font-size: 12*2/75rem;
    line-height: 1.5;
    position: relative;

    &:after{
      content: '';
      position: absolute;
      top: -4*2/75rem;
      right: 120*2/75rem;
      border-left: 6*2/75rem solid transparent;
      border-right: 6*2/75rem solid transparent;
      border-bottom: 5*2/75rem solid #FFF8EB;

    }
  }
  .item-sep-line__right {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      background-color: var(--sui_color_gray_light2, #e5e5e5);
    }
  }
}
</style>
