import { Trade_PayToolKit } from '@shein-aidc/types-trade'
import { cloneDeep } from '@shein/common-function'
interface IGoodsInfo {
  businessModel?: string
  companyId?: string
  business_model?: string
  company_id?: string
}

/**
 * 获取光标位置函数
 * @param dom	当前的输入框元素
 */
export function getCursortPosition(dom: HTMLInputElement) {
  if (dom) {
    let pos = 0
    // IE Support
    if ((document as any).selection && (document as any).selection.createRange) {
      dom.focus()
      const sel = (document as any).selection.createRange()
      sel.moveStart('character', -dom.value.length)
      pos = sel.text.length

      // Firefox support
    } else if (dom.selectionStart || dom.selectionStart == 0) {
      pos = dom.selectionStart
    }
    return (pos)
  } else {
    return 0
  }
}

/**
 * 设置光标位置函数
 * @param dom	当前的输入框元素
 * @param pos	需要设置光标的下标值
 */
export function setCursortPosition(dom: HTMLInputElement, pos: number) {
  if (dom.setSelectionRange) {
    dom.focus()
    dom.setSelectionRange(pos, pos)
  } else if ((dom as any).createTextRange) {
    const range = (dom as any).createTextRange()
    range.collapse(true)
    range.moveEnd('character', pos)
    range.moveStart('character', pos)
    range.select()
  }
}

export function debuggerLog(...args) {
  if (typeof window === 'undefined') return
  try {
    const enableDebugLog = sessionStorage?.getItem?.('__bs__enable_debug_log')
    if (!enableDebugLog) return
    console.info(
      '%cBS Pay Toolkit Debug Info:',
      'background:#35495e ; padding: 3px 3px; border-radius: 3px;  color: #fff',
      ...args,
      '\n',
    )
  } catch (e) {
    console.log(...args)
  }
}

export function getOrderAttributesList({
  normalGoodsList = [],
  usedPrimeProductInfo = {},
  usedXtraProductInfo = {},
}) {
  let orderAttributesList: any[] = []

  const formatAttribute = (goodsInfo: IGoodsInfo = {}) => {
    const { businessModel, business_model, companyId, company_id } = goodsInfo || {}
    return {
      businessModel: businessModel || business_model,
      companyId: companyId || company_id,
    }
  }

  const uniqueBusinessInfo = (list: IGoodsInfo[] = []) => {
    // 需去重
    const hashMap = {}
    return list.reduce((pre, cur) => {
      const hash = `${cur.businessModel}:${cur.companyId || ''}`
      if (!hashMap[hash]) {
        hashMap[hash] = true
        return [...pre, cur]
      }
      return pre
    }, [] as any[])
  }

  orderAttributesList = [
    ...(normalGoodsList?.map?.(item => formatAttribute(item)).filter(v => v.businessModel != null) || []),
  ]
  const primeAttribute = formatAttribute(usedPrimeProductInfo)
  const xtraAttribute = formatAttribute(usedXtraProductInfo)
  if (primeAttribute.businessModel != null) orderAttributesList.push(primeAttribute)
  if (xtraAttribute.businessModel != null) orderAttributesList.push(xtraAttribute)

  return uniqueBusinessInfo(orderAttributesList)
}

export function nextIdle() {
  return new Promise(resolve => {
    const rc = window.requestIdleCallback || window.setTimeout
    rc(() => resolve(true))
  })
}


export function makeForm(params, action, method = 'post') {
  let form = document.querySelector('form[name="pay_jump_form"]')
  if (form) {
    form.parentNode?.removeChild(form)
  }

  method = method || 'post'
  form = document.createElement('form')
  form.setAttribute('name', 'pay_jump_form')
  form.setAttribute('action', action)
  form.setAttribute('method', method)

  for (const key in params) {
    const input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', key)
    input.setAttribute('value', params[key])
    form.appendChild(input)
  }

  const submitInput = document.createElement('input')
  submitInput.setAttribute('type', 'submit')
  submitInput.setAttribute('value', 'Continue')
  submitInput.style.display = 'none'
  form.appendChild(submitInput)

  document.body.appendChild(form)
  // @ts-ignore
  document?.pay_jump_form?.submit?.()
}

export function checkPaymentMethodSupport(paymentItem: Trade_PayToolKit.PaymentInfoItem, { isMounted } = { isMounted: false }) {
  if (paymentItem.is_apple_pay == 1 && isMounted) {
    // @ts-ignore
    return window.ApplePaySession?.canMakePayments?.()
    // return true
  }
  return true
}

export function filterApplePayCardType(paymentItem: Trade_PayToolKit.PaymentInfoItem, { isMounted } = { isMounted: false }): {
  paymentItem: Trade_PayToolKit.PaymentInfoItem,
  isValid: boolean
} {
  if (paymentItem.is_apple_pay == 1 && isMounted) {
    let version = 3
    // @ts-ignore
    if (window.ApplePaySession) {
      version = 14
      // @ts-ignore
      while (!window.ApplePaySession.supportsVersion?.(version)) {
        version--
      }
    }
    const apple_google_support_config_list = paymentItem?.apple_google_support_config_list || []
    paymentItem.card_logo_list = paymentItem.card_logo_list?.filter((logo) => {
      const cardInfo = apple_google_support_config_list?.find(item => item.card_type_logo === logo)
        // 如果版本高于当前applepay支持的版本，或者apple_pay_map中无对应的版本信息，则不展示
        if (cardInfo && cardInfo.web_min_version && Number(cardInfo.web_min_version) <= version) {
          return true
        }
        return false
      }) || []
  }
  return {
    paymentItem,
    isValid: paymentItem.is_apple_pay == 1 ? paymentItem?.apple_google_support_config_list?.length && paymentItem.card_logo_list?.length > 0 : true,
  }
}


export function mergeWith(target, source) {
  for (const key in source) {
    if (source[key] !== undefined) {
      target[key] = source[key]
    }
  }
  return target
}

// 合并两个对象，如果新对象的属性值为undefined，则删除原对象的属性
export function mergeSelectPaymentWithDeleteUndefined(target, newPaymentItem) {
  if (target instanceof Array || newPaymentItem instanceof Array) {
    return [...target, ...newPaymentItem]
  }
  if (typeof target !== 'object' || typeof newPaymentItem !== 'object') {
    return target
  }
  const cloneTarget = cloneDeep(target)
  for (const key in cloneTarget) {
    if (newPaymentItem[key] === undefined) {
      delete cloneTarget[key]
    }
  }
  return Object.assign(cloneTarget, newPaymentItem)
}

export function findPayment(data: Trade_PayToolKit.PaymentInfoItem[], predicate: (v: Trade_PayToolKit.PaymentInfoItem) => boolean): Trade_PayToolKit.SelectedPaymentInfo {
  const queue = [...data] // 使用队列来处理广度优先搜索
  while (queue.length > 0) {
    const node = queue.shift() // 获取并移除队列的第一个元素
    if (node && predicate(node)) return node // 找到匹配的节点，返回该节点
    // 将子节点追加到队列
    if (node?.payments && node?.payments?.length > 0) {
      queue.push(...node.payments)
    }
  }

  return null as any // 没有找到匹配的节点，返回 null
}

let rsObserver: ResizeObserver | null = null
const rsCallbacks = new WeakMap()

export function createResizeObserver(el, callback) {
  rsCallbacks.set(el, callback)
  if (window && 'ResizeObserver' in window) {
    if (!rsObserver) {
      rsObserver = new ResizeObserver((entries) => {
        entries.forEach(entry => {
          const cb = rsCallbacks.get(entry.target)
          if (cb) cb(entry)
        })
      })
    }
  }

  return rsObserver
}

// 替换url协议为//
export function replaceUrlProtocol (url: string) {
  if (!url) return url
  // 边界条件
  if (typeof url !== 'string' || !url.includes('://')) return url
  // 获取协议
  return url.replace(/^(https?):\/\//i, '//')
}
