<template>
  <div
    class="recommendPhone"
    @click.stop>
    <div>
      <div class="recommendPhone__label">{{ bubbleTip }}</div>
      <div class="recommendPhone__phone">
        <span>{{ recommendPhone }}</span>
        <SButton
          class="recommendPhone__use"
          :type="['primary', 'H72PX']"
          @click="clickUseRecommendPhone">
          {{ language.SHEIN_KEY_PWA_27571 }}
        </SButton>
      </div>
      <sui_icon_close_12px_2
        class="recommendPhone__close"
        size="12px"
        color="#666666"
        @click="clickCloseRecommendPhone" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePaymentsStore } from '../../../hooks/usePayments'
import { AS_PayToolkit } from '../../../types'
// @ts-ignore 该库未提供类型定义
import { sui_icon_close_12px_2 } from '@shein-aidc/icon-vue3'
import { computed, inject } from 'vue'
const { triggerNotice } = inject('analysisInstance') as AS_PayToolkit.AnalysisInstance

interface RecommendPhoneProps {
  language: Record<string, string>
  recommendPhone: string
}

withDefaults(defineProps<RecommendPhoneProps>(), {
  language: () => ({}),
  recommendPhone: '',
})

const emits = defineEmits(['click-use', 'click-close'])
const paymentStore = usePaymentsStore()

const bubbleTip = computed(() => {
  return paymentStore.capitecDialogConfig?.bubbleTip
})

const clickUseRecommendPhone = () => {
  emits('click-use')
  triggerNotice({
    id: 'click_phonepop_usebubble:simple',
    data: {
      type: 'ebanx-nequi',
    },
  })
}
const clickCloseRecommendPhone = () => {
  emits('click-close')
  triggerNotice({
    id: 'click_phonepop_closebubble:simple',
    data: {
      type: 'ebanx-nequi',
    },
  })
}

</script>

<style lang="less" scoped>
.recommendPhone {
  --box-border-radius: 0.0533rem;

  position: absolute;
  left: calc(100% + 0.2535rem);
  top: calc(100% + 0.1rem);
  box-shadow: 0px 1px 0.08rem 0px rgba(0, 0, 0, 0.2);
  border-radius: var(--box-border-radius);
  z-index: 110000;

  &:before {
    content: '';
    transform: rotate(45deg);
    background: #fff;
    box-shadow: 0px 1px 0.08rem 0px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -4*2/75rem;
    left: 8*2/75rem;
    width: 8*2/75rem;
    height: 8*2/75rem;
  }

  >div {
    width: 198*2/75rem;
    background-color: #fff;
    position: relative;
    padding: 12*2/75rem;
    border-radius: var(--box-border-radius);

    .recommendPhone__label {
      min-height: 14*2/75rem;
      margin-bottom: 6*2/75rem;
      font-size: 12*2/75rem;
      line-height: 14*2/75rem;
      color: #666;
      font-weight: 600;
      word-break: break-all;
      white-space: normal;
    }

    .recommendPhone__phone {
      display: flex;
      justify-content: space-between;
      align-items: center;

      >span {
        width: 0;
        flex: 1;
        font-size: 14*2/75rem;
        line-height: 16*2/75rem;
        color: #000;
        font-weight: 600;
        word-break: break-all;
        white-space: normal;
      }

      .recommendPhone__use {
        border: none;
        min-width: auto;
        height: 24*2/75rem;
        line-height: 24*2/75rem;
        padding: 0 8*2/75rem;
      }
    }

    .recommendPhone__close {
      position: absolute;
      right: 0;
      top: 0;
      padding: 4*2/75rem;
    }

  }
}
</style>
