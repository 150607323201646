<template>
  <div class="module-item">
    <div class="support-info-title">
      <Icon
        name="sui_icon_payment_security_16px"
        size="16px"
        :is-rotate="cssRight"
        class="icon-width" />
      <span>{{ language.SHEIN_KEY_PWA_30718 }}</span>
    </div>

    <p
      v-for="(item, i) in privacyTextList"
      :key="`text-${i}`"
      class="privacy-text-list">
      <Icon
        name="sui_icon_selected_16px"
        size="16px"
        :is-rotate="cssRight"
        class="text-icon" />
      <span class="privacy-text">
        {{ item.text }}
      </span>
    </p>

    <div class="payment-logo-list">
      <div
        v-for="(item, i) in logoList"
        :key="`token-${i}`"
        class="logo-item">
        <img
          :alt="item"
          class="auto-w lazyload list-img"
          :data-src="item"
          :src="goods_img_placeholder" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { Icon } from '@shein-aidc/icon-vue3'
import { ref, computed } from 'vue'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

const props = defineProps({
  language: {
    type: Object,
    default: () => {},
  },
  logoList: {
    type: Array,
    default: () => [],
  },
})

const { cssRight } = useAppConfigs()?.$envs || {}

const privacyTextList = computed(() => [
  { text: props.language?.SHEIN_KEY_PWA_30719 },
  { text: props.language?.SHEIN_KEY_PWA_30720 },
  { text: props.language?.SHEIN_KEY_PWA_30721 },
])
const goods_img_placeholder = ref('https://img.ltwebstatic.com/images3_ccc/2024/08/20/58/1724133555966cc2d1abdaa086dead698e83363cf4.png')
</script>

<style lang="less" scoped>
.font-dpr(@font-size) {
    font-size: @font-size * 0.5;
}
.support-info-title {
  color: #198055;
  .font-dpr(28px);
  line-height: 1.21428;
  min-height: 40/75rem;
  margin-bottom: 16/75rem;
  display: flex;
  align-items: center;
  .icon-right {
    color: #767676;
  }
  .icon-width {
    width: 32/75rem;
    height: 32/75rem;
    display: inline-block;
    margin-right: 8/75rem;
  }
}

.privacy-text-list {
  margin-bottom: 12/75rem;
  display: flex;
  .text-icon {
    color: #198055 !important;
    margin-right: 8/75rem;
  }
  .privacy-text {
    color: #767676;
    font-size: 12px;
    font-family: SF Pro;
    font-weight: 400;
    word-wrap: break-word;
  }
}

.logo-item {
  height: 40/75rem;
  margin-right: 6/75rem;
  border: 0.53px #e5e5e5 solid;
  padding: 4/75rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .list-img {
    height: 100%;
  }
  &.auto-w {
    width: auto;
  }
}
</style>
