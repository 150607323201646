<template>
  <!-- cvv 提示弹窗 -->
  <SDialog
    v-model:visible="isShowCvvTip"
    :show-close="true"
    :append-to-body="true"
    @close="closeCvvTip"
  >
    <template
      v-if="isShowDynamicCvv"
      #title>
      {{ language.SHEIN_KEY_PWA_36256 }}
    </template>
    <div
      v-if="isShowDynamicCvv"
      class="dynamic-cvv-tips">
      <p v-if="language.SHEIN_KEY_PWA_36234">{{ language.SHEIN_KEY_PWA_36234 }}</p>
      <p v-if="language.SHEIN_KEY_PWA_36235">{{ language.SHEIN_KEY_PWA_36235 }}</p>
      <p v-if="language.SHEIN_KEY_PWA_36236">{{ language.SHEIN_KEY_PWA_36236 }}</p>
    </div>
    <div
      v-else
      class="cvv-tip-content">
      <p tabindex="0">
        {{ language.SHEIN_KEY_PWA_30130 }}
      </p>
      <img
        src="https://img.ltwebstatic.com/images3_ccc/2024/09/02/1b/1725283012c8cbc85e7c69d0b70f8b265c06875e28.jpg"
        aria-hidden="true"
      />
    </div>
    <template #footer>
      <div>
        <SButton
          v-if="isShowDynamicCvv"
          class="cvv-tips-ok"
          :type="['primary', 'H72PX']"
          @click="closeCvvTip"
        >{{ language.SHEIN_KEY_PWA_33715 }}</SButton>
      </div>
    </template>
  </SDialog>
</template>

<script name="CvvTipsPopup" setup lang="ts">
/*
 * 支付方式组件: cvv提示弹窗
 *
 * */

import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'


// utils
import { computed, defineEmits } from 'vue'
// import { type LangKeys } from '../../../common/languages'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  language: {
    type: Object,
    default: () => {},
  },
  isShowDynamicCvv: {
    type: Boolean,
    default: false,
  },
})

const isShowCvvTip = computed({
  get: () => props.visible,
  set: (val) => emit('update:visible', val),
})

const emit = defineEmits(['update:visible'])

const closeCvvTip = () => {
  isShowCvvTip.value = false
}

</script>

<style lang="less" scoped>
.dynamic-cvv-tips{

> p{
  padding-top: 12*2/75rem;
}
}
.cvv-tip-content {
  text-align: center;
  p {
    color: #999;
  }
  img {
    width: 100%;
    height: 100%;
    margin: 0;
  }
}
.cvv-tips-ok {
  width: 100%;
}
</style>
