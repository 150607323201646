<template>
  <SDialog
    v-model:visible="visibleRememberCardTip"
    :show-close="true"
    append-to-body
  >
    <p>{{ language.SHEIN_KEY_PWA_21347 }}</p>
    <template #footer>
      <SButton
        :type="['primary']"
        :width="'100%'"
        @click="visibleRememberCardTip = false"
      >
        {{ language.SHEIN_KEY_PWA_28760 }}
      </SButton>
    </template>
  </SDialog>
</template>

<script setup>
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { computed } from 'vue'

const emits = defineEmits(['update:visible'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  language: {
    type: Object,
    default: () => ({}),
  },
})

const visibleRememberCardTip = computed({
  get: () => props.visible,
  set: (val) => {
    emits('update:visible', val)
  },
})

</script>
