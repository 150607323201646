<template>
  <SDrawer
    v-model:visible="isShowTokenDrawer"
    class="token-pay-drawer new-security-drawer"
    :direction="'btt'"
    :append-to-body="true"
    @close="close"
  >
    <template #title>
      <div class="header">
        <span class="header-title">{{ language.SHEIN_KEY_PWA_23430 }}</span>
        <div
          class="all-encrypted"
          @click="showPrivacyDrawer"
        >
          <Icon
            name="sui_icon_guarantee_18px"
            size="18px"
            :is-rotate="cssRight"
          />
          <span>{{ language.SHEIN_KEY_PWA_30725 }}</span>
          <Icon
            name="sui_icon_more_right_12px_2"
            size="12px"
            :is-rotate="cssRight"
          />
        </div>
      </div>
    </template>
    <div class="token-content">
      <div
        v-if="isShowUseNewCard"
        class="new-card-block"
        @click="useNewCard"
      >
        <div class="new-card">
          <div class="new-card-img"></div>
          <div class="new-card-text">
            {{ language.SHEIN_KEY_PWA_30050 }}
          </div>
          <Icon
            name="sui_icon_more_right_16px"
            size="16px"
            class="left-icon"
            style="color: #959595"
            :is-rotate="cssRight"
          />
        </div>
      </div>

      <BSrcTokenHeader
        v-if="consumerPresent"
        ref="srcTokenHeaderRef"
        class="src-token-header"
        @clickToPay="handleStartAccessYourCards"
      />

      <div
        v-if="nowTokenPayIsHadToken"
        class="init-token-block"
      >
        <div
          v-if="isShowC2pTokenList"
          class="title"
        > {{ language.SHEIN_KEY_PWA_35823 || 'Credit/Debit card' }} </div>
        <TokenList
          ref="tokenListRef"
          :language="language"
          :payment-list="paymentList"
          :is-show="isShowTokenDrawer"
          :payment-config="paymentConfig"
          :c2p-token-list="c2pTokenList"
          @close="close"
          @useNewCard="useNewCard"
        />
      </div>

      <div
        v-if="isShowC2pTokenList"
        class="c2p-token-block"
      >
        <div class="c2p-token-header">
          <div class="title"> {{ language.SHEIN_KEY_PWA_35940 || 'Click to pay card' }} </div>
          <BSrcCardList :cardTypeList="availableCardBrands" />
          <div
            class="not-your-card"
            @click="handlerNoYou"
          > {{ language.SHEIN_KEY_PWA_36084 || 'Not your card' }} </div>
        </div>
        <TokenList
          ref="c2pTokenListRef"
          :language="language"
          :payment-list="paymentList"
          :is-show="isShowTokenDrawer"
          :is-c2p="true"
          :c2p-token-list="c2pTokenList"
          @close="close"
        />
      </div>

      <!-- <TokenList
        :language="language"
        :payment-list="paymentList"
        :is-show="isShowTokenDrawer"
        :payment-config="paymentConfig"
        @close="close"
      /> -->
      <AllPrivacy :language="language" />
    </div>
    <SLazyMount>
      <PrivacyDrawer
        v-model:visible="isShowPrivacyDrawer"
        :language="language"
      >
        <template #privacy>
          <NewPrivacy
            :language="language"
            :logo-list="logoList"
          />
        </template>
      </PrivacyDrawer>
    </SLazyMount>
  </SDrawer>
</template>

<script name="ChooseTokenDrawer" setup lang="ts">
/**
 * 卡 token 选择抽屉
 */
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import { ref, computed, watch, nextTick, inject } from 'vue'
import TokenList from './TokenList.vue'
import type { PaymentItemProps } from '../../../../types/props'
import { Icon } from '@shein-aidc/icon-vue3'
import AllPrivacy from '../privacy/all_privacy.vue'
import NewPrivacy from '../privacy/new_privacy.vue'
import PrivacyDrawer from '../privacy/privacy_drawer.vue'
import { usePaymentsStore } from '../../../../hooks/usePayments'
import { useEditablePaymentInfo } from '../../../../hooks/useEditablePaymentInfo'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { BSrcTokenHeader, BSrcCardList } from '@shein-aidc/bs-click-to-pay-mobile'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import type { AS_PayToolkit } from '../../../../types'

interface ChooseTokenDrawerProps {
    language: Record<string, string>;
    paymentList: PaymentItemProps['payments'];
    visible: boolean;
    c2pInfo?: Record<string, any>;
    paymentConfig?: Trade_PayToolKit.PaymentConfig;
}
const { triggerNotice } = (inject('analysisInstance', null) || {}) as AS_PayToolkit.AnalysisInstance

const props = withDefaults(defineProps<ChooseTokenDrawerProps>(), {
    language: () => ({}),
    paymentList: () => [],
})

const emit = defineEmits(['update:visible'])

const { cssRight } = useAppConfigs()?.$envs || {}

const paymentState = usePaymentsStore()
const { handleEditableInfoChange } = useEditablePaymentInfo()

const isShowTokenDrawer = computed({
  get: () => props.visible,
  set: (val) => emit('update:visible', val),
})
const isShowPrivacyDrawer = ref(false)
const c2pTokenListRef = ref(null)
const srcTokenHeaderRef = ref(null)

const isShowUseNewCard = computed(() => paymentState?.showTokenDrawerPay === 'routepay-cardinstallment')

const logoList = computed(() => {
  return paymentState?.paySafeIconsList || []
})

const c2pTokenList = computed(() => {
  return props.c2pInfo?.c2pTokenList || []
})

const consumerPresent = computed(() => {
  console.log('props.c2pInfo?.consumerPresent >>>>', props.c2pInfo?.consumerPresent, c2pTokenList.value)
  return paymentState?.showTokenDrawerPay === 'routepay-card'
    && props.c2pInfo?.consumerPresent
    && c2pTokenList.value?.length === 0
    && !props.c2pInfo?.isNoShowBSrcTokenHeader
})

const isShowC2pTokenList = computed(() => {
  return paymentState?.showTokenDrawerPay === 'routepay-card' && c2pTokenList.value?.length > 0
})

const nowTokenPayIsHadToken = computed(() => {
  return ((props?.paymentList || [])?.find(item => item?.code === paymentState?.showTokenDrawerPay)?.card_token_list || [])?.length > 0
})

const availableCardBrands = computed(() => {
  return props?.c2pInfo?.availableCardBrands || []
})

const close = () => {
    isShowTokenDrawer.value = false
}

const showPrivacyDrawer = () => {
    isShowPrivacyDrawer.value = true
}

const useNewCard = () => {
  close()
  handleEditableInfoChange({
      changeInfo: {
          [paymentState?.showTokenDrawerPay]: {
              ...(paymentState?.editablePaymentInfo?.[paymentState?.showTokenDrawerPay] || {}),
              card_token_info: {},
              isUseNewCard: true,
          },
      },
      needEmitChangeEvent: true,
      extraInfo: {
        isNeedPostCheckout: true,
      },
  })
}
const handleStartAccessYourCards = () => {
  triggerNotice({
        id: 'click_access_ctp:simple',
    })
  props.c2pInfo?.handleStartAccessYourCards?.({ scene: 'access_your_cards' })
}

const handlerNoYou = () => {
    triggerNotice({
        id: 'click_not_yourcard:simple',
    })
    props?.c2pInfo?.handleNotYou?.(() => {
      close()
    })
}

watch(
  () => isShowC2pTokenList.value,
  val => {
    if (val) {
      console.log('language >>>>', props.language)
      nextTick(() => {
        props?.c2pInfo?.setHandlerC2pTokenCheck?.(c2pTokenListRef.value?.handlerCheck || null)
      })
    }
  },
  {
    immediate: true,
  },
)
</script>

<style lang="less" scoped>
.header {
  line-height: 34/75rem;
  padding: 18/75rem 0 14/75rem 0;

  .header-title {
    color: #767676;
    font-size: 14px;
    font-family: SF Pro;
    font-weight: 590;
    word-wrap: break-word;

    .title-c2p-img {
      display: inline-block;
      width: 30*2/75rem;
      height: 20*2/75rem;
      background-image: url(https://img.ltwebstatic.com/v4/p/ccc/2025/02/19/82/173997063924d39199c3faca1e085da0c707ed0988.webp);
      background-size: 100% 100%;
      margin-right: 4 * 2/75rem;
    }
  }

  .all-encrypted {
    height: 36/75rem;
    line-height: 36/75rem;
    color: #198055;
    font-size: 12px;
    font-family: SF Pro;
    font-weight: 400;
    word-wrap: break-word;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.token-content {
  background-color: #f6f6f6;
  .new-card-block {
    background-color: white;
    margin-bottom: 8 * 2/75rem;
    padding: 12 * 2/75rem;

    .new-card {
      width: 100%;
      border-radius: 4px;
      border: 1px #999999 dotted;
      padding: 18 * 2/75rem 12 * 2/75rem;
      display: flex;
      align-items: center;
    }

    .new-card-img {
      width: 30px;
      height: 20px;
      background-image: url(https://img.ltwebstatic.com/images3_ccc/2024/06/14/aa/1718352340ff2421c5efcedb6a17bb10bd997ca733.png);
      background-size: cover;
    }

    .new-card-text {
      color: black;
      font-size: 14px;
      font-family: SF UI Text;
      font-weight: 400;
      word-wrap: break-word;
      margin-left: 8 * 2/75rem;
      flex: 1;
    }
  }
  .src-token-header {
    margin-bottom: 8 * 2/75rem;
  }
}
.init-token-block {
  .title {
    padding: .32rem .32rem 4 * 2/75rem;
    background-color: white;
    color: black;
    font-size: 14px;
    font-weight: 700;
    word-wrap: break-word;
    // 超出一行展示省略号
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.c2p-token-block {
  .c2p-token-header {
    padding: 24*2/75rem .32rem 0;
    background-color: white;

    .title {
      color: black;
      font-size: 14px;
      font-weight: 700;
      word-wrap: break-word;
      margin-bottom: 4 * 2/75rem;
      // 超出一行展示省略号
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .src-card-list {
      background-color: white;
      margin-bottom: 4 * 2/75rem;
    }

    .not-your-card {
      color: #2D68A8;
      font-size: 12px;
      font-weight: 400;
      text-decoration: underline;
      word-wrap: break-word;
    }
  }
}

.new-security-drawer {
  /deep/.sui-drawer__header_normal {
    min-height: unset;
  }
}
</style>
