<template>
  <SDrawer
    v-model:visible="isShow"
    class="pay-privacy-drawer"
    :direction="'btt'"
    :append-to-body="true"
    @close="close">
    <template #title>
      <span class="header-title">{{ language.SHEIN_KEY_PWA_30730 }}</span>
    </template>

    <div class="privacy-drawer-content">
      <div class="new-privacy">
        <slot name="privacy"></slot>
      </div>

      <div class="security-content">
        <div class="support-info-title">
          <Icon
            name="sui_icon_privacy_16px"
            size="16px"
            :is-rotate="cssRight"
            class="icon-width" />
          <span>{{ language.SHEIN_KEY_PWA_23520 }}</span>
        </div>

        <p
          v-for="(item, i) in securityTextList"
          :key="`text-${i}`"
          class="security-text-list">
          <Icon
            name="sui_icon_selected_16px"
            size="16px"
            :is-rotate="cssRight"
            class="text-icon" />
          <span class="security-text">
            {{ item.text }}
          </span>
        </p>

        <p
          class="learn-more"
          @click="toPrivacy">
          <span>{{ language.SHEIN_KEY_PWA_30729 }}</span>
          <Icon
            name="sui_icon_more_right_12px_2"
            size="12px"
            :is-rotate="cssRight"
          />
        </p>
      </div>
    </div>
  </SDrawer>
</template>

<script setup>
import { ref, watch, computed } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

const props = defineProps({
  language: {
    type: Object,
    default: () => {},
  },
  visible: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:visible'])

const { cssRight } = useAppConfigs()?.$envs || {}

const isShow = ref(false)
const securityTextList = computed(() => [
  { text: props.language?.SHEIN_KEY_PWA_30722 },
  { text: props.language?.SHEIN_KEY_PWA_30723 },
  { text: props.language?.SHEIN_KEY_PWA_30724 },
])

watch(
  () => props.visible,
  () => {
    if (props.visible !== isShow.value) {
      isShow.value = props.visible
    }
  },
  {
    immediate: true,
  },
)

const close = () => {
  emit('update:visible', false)
  isShow.value = false
}

const toPrivacy = () => {
  window.SHEIN_GLOBAL_PRIVACY.show({ type: 'privacy' })
}
</script>

<style scoped lang="less">
.font-dpr(@font-size) {
    font-size: @font-size * 0.5;
}
.new-privacy {
  padding: 32/75rem 24/75rem 24/75rem 24/75rem;
  background-color: white;
}

.security-content {
  padding: 32/75rem 24/75rem 24/75rem 24/75rem;
  background-color: white;
  margin-top: 16/75rem;
}

.security-text-list {
  margin-bottom: 12/75rem;
  display: flex;
  .text-icon {
    color: #198055 !important;
    margin-right: 8/75rem;
  }
  .security-text {
    color: #767676;
    font-size: 12px;
    font-family: SF Pro;
    font-weight: 400;
    word-wrap: break-word;
  }
}

.pay-privacy-drawer {
  /deep/.sui-drawer__body {
    background-color: unset;
  }
}

.support-info-title {
  color: #198055;
  .font-dpr(28px);
  line-height: 1.21428;
  min-height: 40/75rem;
  margin-bottom: 16/75rem;
  display: flex;
  align-items: center;
  .icon-right {
    color: #767676;
  }
  .icon-width {
    width: 32/75rem;
    height: 32/75rem;
    display: inline-block;
    margin-right: 8/75rem;
  }
}

.learn-more {
  color: #767676;
  font-size: 12px;
  font-family: SF Pro;
  font-weight: 400;
  word-wrap: break-word;
  padding-left: 40/75rem;
  display: flex;
  align-items: center;
}
</style>
