import { CARD_TYPE_ENUM } from '../common/constants'

// name格式校验
export function cardNameValidator({ cardName = '' }) {
  const reg = /^.{2,50}$/

  const isInvalidNameOfDigitsError = !reg.test(cardName)

  if (!cardName) return 'null'
  if (isInvalidNameOfDigitsError) return 'character'
  return ''
}

/**
 * Luhn算法校验
 *
 * @param {*} num
 * @return {*}
 */
function luhnCheck(num) {
  const arr = num.split('')
  let total_num = 0
  arr.reverse()

  arr.forEach((str, index) => {
    let val = Number(str)
    //偶数位
    if (index % 2) {
      val *= 2
      //如果位数大于2，在10-18之间，位数相加
      if (val > 9) {
        val = 1 + (val - 10)
      }
    }
    total_num += val
  })

  if (total_num % 10) {
    return false
  }
  return true
}

/**
 * 首位数校验
 *
 * @param {*} num
 * @return {*}
 */
function firstNumberVerify(num) {
  return !/^0/.test(`${num}`)
}

/**
 * 卡号位数校验
 *
 * @param {*} num
 * @return {*}
 */
// function numberLengthVerify(num) {
//   return /^\d{15,19}$/g.test(`${num}`)
// }

function oldNumberLengthVerify(num) {
  return /^\d{12,20}$/g.test(`${num}`)
}

function emptyVerify(num) {
  return !!num
}

const getCardNumberRules = (language) => [
  {
    validator: emptyVerify,
    message: language?.SHEIN_KEY_PWA_25761,
    failType: 'null',
  },
  {
    validator: firstNumberVerify,
    message: language?.SHEIN_KEY_PWA_25762,
    failReason: 3,
    regular: '!/^0/',
    failType: 'first_number',
  },
  {
    validator: oldNumberLengthVerify,
    message: language?.SHEIN_KEY_PWA_25764,
    failReason: 1,
    regular: '/^\\d{12,20}$/g',
    failType: 'num_of_digits',
  },
  {
    validator: luhnCheck,
    message: language?.SHEIN_KEY_PWA_25764,
    failReason: 2,
    failType: 'luna',
  },
]

/**
 * 卡号校验
 *
 * @param {*} {
 *   cardNumber = '',
 *   language = {}
 * }
 * @return {{result: boolean, message: string, failType: string, failReason: number} | {result: boolean}} {
  * result: boolean,
  * message?: string,
  * failType?: string,
  * failReason?: number
 * }
 */
export function cardNumberValidator({
  cardNumber = '',
  language = {},
}): { result: boolean, message?: string, failType?: string, failReason?: number } {
  const rules = getCardNumberRules(language)
  //卡号验证
  for (let i = 0, len = rules.length; i < len; i++) {
    const { validator, ...resetInfo } = rules[i]
    if (!validator(cardNumber)) {
      return { ...resetInfo, result: false }
    }
  }
  return { result: true, message: '', failType: '' }
}

export function cpfValidator ({
  cpf = '',
  rule = '',
}) {
  if (!rule) return ''
  const reg = new RegExp(rule || '')
  const isInvalidCpfOfDigitsError = !reg?.test?.(cpf)
  if (!cpf) return 'null'
  if (isInvalidCpfOfDigitsError) return 'expression'
  return ''
}

// cvv格式校验
export function cvvValidator({ cvv, cardType }) {
  const SPECIAL_CVV_LIST = ['0000'] // 特殊的不支持输入的 cvv 卡号

  const regObj = {
    AMEX: /^\d{3,4}$/,
    MAESTRO: /^(\d{3}|\s{0})$/,
    other: /^\d{3}$/,
  }

  let reg = /^\d{3,4}$/ // 默认匹配不上,卡种返回异常
  if (cardType) {
    reg = regObj[cardType.toUpperCase()] || regObj['other']
  }

  const isNumOfDigitsError = !reg.test(cvv)
  const isInvalidNumber = SPECIAL_CVV_LIST.includes(cvv)

  // 当卡种返回非AE卡（包括V/M）时，输入框CVV支持输入3位000；
  if (!isNumOfDigitsError || cvv == '000') return ''

  // 当卡种返回AE卡时，输入框CVV支持输入3位000或者4位0000；
  if (cardType.value == CARD_TYPE_ENUM.AMEX && cvv == '0000') return ''

  if (!cvv) return 'null'
  if (isNumOfDigitsError) return 'num_of_digits'
  if (isInvalidNumber) return 'invalid_number'
}

