<template>
  <SDialog
    v-model:visible="visibleFailGuide"
    append-to-body
    :show-close="true"
    class="fail-guide-modal"
    @close-from-icon="toggleMask(true)"
    @close-from-mask="toggleMask(true)"
  >
    <template #title>
      <div
        class="mask-fail-tip"
        tabindex="0"
        :class="{'redfont': type == 'payError'}"
        v-html="text"
      ></div>
    </template>
    <template
      v-if="hasGuide"
      #footer
    >
      <SButton
        width="335px"
        :type="['primary']"
        @click="goRefill"
      >
        {{ language.SHEIN_KEY_PWA_23438 }}
      </SButton>
      <SButton
        width="335px"
        style="margin-top: 10px;"
        @click="goDetail"
      >
        {{ language.SHEIN_KEY_PWA_23439 }}
      </SButton>
    </template>
    <template
      v-else
      #footer
    >
      <SButton
        :type="['primary']"
        @click="toggleMask(true)"
      >
        {{ language.SHEIN_KEY_PWA_28760 }}
      </SButton>
    </template>
  </SDialog>
</template>

<script setup>
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { ref, watch } from 'vue'

const emits = defineEmits(['update:visible'])

const props = defineProps({
  visible: Boolean,
  hasGuide: {
    type: Boolean,
    default: false,
  },
  text: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: '',
  },
  language: {
    type: Object,
    default: () => {},
  },
  closeCb: {
    type: Function,
    default: () => {},
  },
  confirmCb: {
    type: Function,
    default: () => {},
  },
})

const visibleFailGuide = ref(props.visible)

watch(() => props.visible, (val) => {
  visibleFailGuide.value = val
})

const toggleMask = (activelyClose = false) => {
  visibleFailGuide.value = false
  emits('update:visible', false)
  props.closeCb({ isActivelyClose: activelyClose })
}

const goRefill = () => {
  visibleFailGuide.value = false
  emits('update:visible', false)
  props.confirmCb()
}

const goDetail = () => {
  toggleMask(false)
}


</script>

