import { ref } from 'vue'
import useApis from '../../../../hooks/useApis'
import { type C_PayToolkit } from '../../../../types'

export const useCheckoutPreRouting = () => {
  const { fetchPrePayRoutingForCheckoutApi, queryUnPayPreRoutingInfoApi } = useApis()
  const cardBinRoutingInfo = ref({})

  const fetchPrePayRoutingForCheckout = async ({
    orderAttributesList,
    orderCurrency,
    orderUsdAmount,
    orderAmount,
    shipCountry,
    virtualOrderScene,
    routeCode,
    bin = '',
  }: C_PayToolkit.FetchPrePayRoutingForCheckoutData = {} as C_PayToolkit.FetchPrePayRoutingForCheckoutData) => {
    try {
      const params: C_PayToolkit.FetchPrePayRoutingForCheckoutData = {
        bin,
        orderAttributesList,
        orderCurrency,
        orderUsdAmount,
        orderAmount,
        shipCountry,
        routeCode,
      }
      if (virtualOrderScene) params.virtualOrderScene = virtualOrderScene
      const data = await fetchPrePayRoutingForCheckoutApi(params)
      const routingInfo = data?.info || data || {}
      // 如果是卡路由，且没有返回payMethod，这里兼容将routingInfo.payMethod赋值为prePayCheckoutInfo.value?.routeCode
      if (
        !routingInfo?.payMethod &&
        (
          routeCode === 'routepay-card' ||
          routeCode === 'routepay-cardinstallment'
        )
      ) {
        routingInfo.payMethod = routeCode
      }
      cardBinRoutingInfo.value = routingInfo
      console.log('fetchPrePayRoutingForCheckout====', data)
      return routingInfo
    } catch (error) {
      // prePayRoutingInfoError.value = error
      console.error('fetchPrePayRoutingForCheckoutApi error', error)
    }
  }

  const handleQueryUnPayPreRoutingInfo = async ({
    bin = '',
    billno = '',
    routeCode = '',
    orderCurrency = '',
    countryCode = '',
    paymentMethod = '',
    queryCardBin = 1,
  } = {}) => {
    const queryCardInstallment = routeCode === 'routepay-cardinstallment' ? 1 : 0
    return queryUnPayPreRoutingInfoApi({
      bin,
      billno,
      routeCode,
      orderCurrency,
      countryCode,
      paymentMethod,
      queryCardBin,
      queryCardInstallment,
    }).then(data => {
      // 如果是卡路由，且没有返回payMethod，这里兼容将routingInfo.payMethod赋值为prePayCheckoutInfo.value?.routeCode
      const routingInfo = data?.info || {}
      if (
        !routingInfo?.payMethod &&
        (
          routeCode === 'routepay-card' ||
          routeCode === 'routepay-cardinstallment'
        )
      ) {
        routingInfo.payMethod = routeCode
      }
      return {
        cardBinDiscountInfo: data?.cardBinDiscountInfo || {},
        installments: data?.installments || [],
        routingInfo,
      }
    })
  }

  const updateCardBinRoutingInfo = (data) => {
    cardBinRoutingInfo.value = data
  }

  return {
    cardBinRoutingInfo,
    updateCardBinRoutingInfo,
    fetchPrePayRoutingForCheckout,
    handleQueryUnPayPreRoutingInfo,
  }
}
