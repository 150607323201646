<template>
  <SLazyMount>
    <SDialog
      v-model:visible="capitecVisible"
      :show-close="true"
      :append-to-body="true"
      class="payment-capitec-dialog"
      @close="handleClose"
      @close-from-icon="handleManualClose"
    >
      <div class="capitec-dialog-container">
        <div :class="isSpecialPayment ? 'capitec-dialog-title__special' : 'capitec-dialog-title'">
          {{ titleText }}
        </div>
        <div class="capitec-dialog-form">
          <div
            v-if="dialogConfig.needCollectPhoneNumber"
            class="form-item"
          >
            <SInput
              ref="phoneRef"
              v-model="capitecDialogData.phone"
              :label="language.SHEIN_KEY_PWA_30560"
              placeholder=""
              variant="field-box"
              @blur="onPhoneInputBlur"
              @input="onPhoneInputChange"
            >
              <template #prepend>
                <div class="form-item__phoneCountryNum">
                  {{ dialogConfig.phoneCountryNum }}

                  <RecommendPhone
                    v-if="recommendPhoneVisible && recommendPhone"
                    :language="language"
                    :recommendPhone="recommendPhone"
                    @click-use="clickUseRecommendPhone"
                    @click-close="clickCloseRecommendPhone" />
                </div>
              </template>
            </SInput>
            <div
              v-if="!isMbwayPayment"
              class="diy-note"
            >
              <div
                v-if="isShowEndorsementSafetyTips"
                class="endorsement-safety-tips">
                <img
                  class="endorsement-safety-tips__icon"
                  src="https://img.ltwebstatic.com/images3_ccc/2024/12/23/76/1734953406fa2bb8672ff9d71bd6cfb75aaf7f02c8.png"
                  alt="" />
                <span
                  class="endorsement-safety-tips__text"
                >{{ capitecDialogConfig.endorsementSafetyTips }}</span>
              </div>
              <span v-else>{{ language.SHEIN_KEY_PWA_30420 }}</span>
            </div>
          </div>
          <div
            v-if="dialogConfig.needCollectEmail"
            class="form-item"
          >
            <SInput
              v-show="!isSpecialPayment"
              ref="emailRef"
              v-model="capitecDialogData.email"
              :label="language.SHEIN_KEY_PWA_30561"
              placeholder=""
              variant="field-box"
              @blur="onEmailInputBlur"
              @input="onEmailInputChange"
            />

            <div v-show="isSpecialPayment">
              <div class="email">
                <input
                  v-model="capitecDialogData.email"
                  type="text"
                  class="email_val"
                />
              </div>
              <p
                v-show="errorTip"
                class="error-tip"
              >
                {{ errorTip }}
              </p>
            </div>

            <p
              v-show="!isSpecialPayment"
              class="diy-note"
            >
              {{ language.SHEIN_KEY_PWA_30421 }}
            </p>
          </div>
        </div>
      </div>
      <template #footer>
        <SButton
          :type="['primary', 'H72PX']"
          :width="'100%'"
          @click="submit"
        >
          {{ btnText }}
        </SButton>
      </template>
    </SDialog>
  </SLazyMount>
</template>

<script setup lang="ts">
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import { SInput } from '@shein-aidc/sui-input/mobile'
import { computed, ref, watch, nextTick, inject } from 'vue'
import useCapitecDialog from '../../../hooks/useCapitecDialog'
import { AS_PayToolkit } from '../../../types'
const { triggerNotice } = inject('analysisInstance') as AS_PayToolkit.AnalysisInstance
import { usePaymentsStore } from '../../../hooks/usePayments'
import RecommendPhone from './RecommendPhone.vue'
import { Trade_PayToolKit } from '@packages/types/trade'

interface CapitecDialogProps {
  language: Record<string, string>;
  collectionInfo: {
    pay_phone: string;
    pay_email: string;
  };
}

const paymentStore = usePaymentsStore()

const emits = defineEmits(['dialogClose', 'submit', 'pay-now'])

const props = withDefaults(defineProps<CapitecDialogProps>(), {
  language: () => ({}),
  collectionInfo: () => ({
    pay_phone: '',
    pay_email: '',
  }),
})

const specialPayment = ['ebanx-boleto', 'dlocal-boleto']

const {
  capitecVisible,
  dialogConfig,
  capitecDialogData,
  genReportConfirmReason,
  closeCapitecDialog,
  recommendPhone,
  recommendPhoneVisible,
  hideRecommendPhone,
  checkAliasApi,
} = useCapitecDialog()

const phoneRef = ref<SInput>(null)

const emailRef = ref<SInput>(null)

const errorTip = ref('')

const capitecDialogConfig = computed(() => {
  return paymentStore.capitecDialogConfig
})

const phoneCountryCode = computed(() => {
  return capitecDialogConfig.value?.phoneCountryNum?.split('+')?.[1] || ''
})

// 新交互需要展示安全提示
const isShowEndorsementSafetyTips = computed(() => {
  return capitecDialogConfig.value?.isNewPhoneShow === '1' && capitecDialogConfig.value?.endorsementSafetyTips
})

const isSpecialPayment = computed(() => {
  return specialPayment.includes(dialogConfig.value.paymentCode)
})

const isMbwayPayment = computed(() => {
  return ['adyen-mbway', 'routepay-mbway'].includes(dialogConfig.value.paymentCode)
})

const titleText = computed(() => {
    if (isSpecialPayment.value) {
      return props.language.SHEIN_KEY_PWA_16236
    } else if (dialogConfig.value.needCollectEmail && dialogConfig.value.needCollectPhoneNumber) {
      return props.language.SHEIN_KEY_PWA_30419
    } else if (dialogConfig.value.needCollectEmail) {
      return props.language.SHEIN_KEY_PWA_31533
    } else if (dialogConfig.value.needCollectPhoneNumber) {
      return props.language.SHEIN_KEY_PWA_20119
    }
    return ''
  })

const btnText = computed(() => {
  if (isSpecialPayment.value) {
    return props.language.SHEIN_KEY_PWA_15068 || 'Confirm'
  } else {
    return props.language.SHEIN_KEY_PWA_15752 || 'Confirm'
  }
})

const handlePhoneErrorTip = ({ validateMessage = '', valid = false }) => {
  phoneRef.value?.checkStatus?.({
    valid,
    message: validateMessage,
  })
}

const handleClose = () => {
  emits('dialogClose')
  hideRecommendPhone()
  isMbwayPayment.value && handlePhoneErrorTip({ valid: true })
  dialogConfig.value.handleCancel?.()
}

const handleManualClose = () => {
  isMbwayPayment.value && handlePhoneErrorTip({ valid: true })
  triggerNotice({
    id: 'click_close.comp_capitec_dialog',
    data: {
      payment_method: dialogConfig.value.paymentCode,
      bank_code: dialogConfig.value?.bankInfo?.code || '',
    },
  })

  paymentStore?.paymentEventBus?.paymentValidSendBi?.emit({
      result_reason: '1_14',
  })
}

const validateMbwayPhone = async ({ phone, code }) => {
  const data = {
    area_abbr: dialogConfig.value.countryCode || '',
    area_code: parseInt(code),
    alias: phone,
    alias_type: 2,
  }
  const res = await checkAliasApi(data)
  if (res?.code != '0') return false
  return true
}

const handleValidateMbwayPhone = async () => {
  capitecDialogData.value.phoneError = 0
  if (!capitecDialogData.value.phone) {
    handlePhoneErrorTip({
      validateMessage: props.language.SHEIN_KEY_PWA_30559 || '',
      valid: false,
    })
    capitecDialogData.value.phoneError = 1
  } else {
    const valid = await validateMbwayPhone({
      phone: capitecDialogData.value.phone,
      code: phoneCountryCode.value,
    })
    handlePhoneErrorTip({
      validateMessage: valid ? '' : props.language.SHEIN_KEY_PWA_20120,
      valid,
    })
    if (!valid) {
      capitecDialogData.value.phoneError = 2
    }
  }
  return !capitecDialogData.value.phoneError
}

const onPhoneInputBlur = () => {
  capitecDialogData.value.phoneError = 0
  if (!capitecDialogData.value.phone) {
    phoneRef.value?.checkStatus?.({
      valid: false,
      message: props.language.SHEIN_KEY_PWA_30559 || '',
    })
    capitecDialogData.value.phoneError = 1
    return
  }
  // mbway 支付方式不走正则校验
  if (isMbwayPayment.value) {
    handlePhoneErrorTip({
      valid: true,
    })
    return
  }
  if (dialogConfig.value.phoneRule?.reg) {
    let valid = new RegExp(dialogConfig.value.phoneRule?.reg).test(capitecDialogData.value.phone)
    phoneRef.value?.checkStatus?.({
      valid,
      message: dialogConfig.value.phoneRule.copywrite || '',
    })
    if (!valid) {
      capitecDialogData.value.phoneError = 2
      return
    }
  }

  // 接口返回的校验规则
  if (Array.isArray(dialogConfig.value.phoneCheckRule)) {
    const notValid = dialogConfig.value.phoneCheckRule.find((rule: Trade_PayToolKit.RegConfig) => !new RegExp(rule.reg).test(capitecDialogData.value.phone))
    phoneRef.value?.checkStatus?.({
      valid: !notValid,
      message: notValid?.copywrite || '',
    })

    if (notValid) {
      capitecDialogData.value.phoneError = 2
      return
    }
  }
}

const onPhoneInputChange = () => {
  if(!capitecDialogData.value.isPhoneChange){
    capitecDialogData.value.isPhoneChange = true
    triggerNotice({
      id: 'click_input.comp_capitec_dialog',
      data: {
        payment_method: dialogConfig.value.paymentCode,
        bank_code: dialogConfig.value?.bankInfo?.code || '',
        input_type: 'phone',
      },
    })
  }
}

// 点击使用推荐手机号
const clickUseRecommendPhone = () => {
  hideRecommendPhone()
  capitecDialogData.value.phone = recommendPhone.value
  nextTick(() => {
    onPhoneInputBlur()
  })
}

// 点击关闭推荐手机号
const clickCloseRecommendPhone = () => {
  hideRecommendPhone()
  phoneRef.value?.focus?.()
}

const onEmailInputBlur = () => {
  capitecDialogData.value.emailError = 0
  if (!capitecDialogData.value.email) {
    emailRef.value?.checkStatus?.({
      valid: false,
      message: props.language.SHEIN_KEY_PWA_15741 || '',
    })
    errorTip.value = props.language.SHEIN_KEY_PWA_15741 || ''
    capitecDialogData.value.emailError = 1
    return
  }
  if (dialogConfig.value.emailRule?.reg) {
    let valid = new RegExp(dialogConfig.value.emailRule.reg).test(capitecDialogData.value.email)
    emailRef.value?.checkStatus?.({
      valid,
      message: props.language.SHEIN_KEY_PWA_16237 || '',
    })
    if (!valid) {
      errorTip.value = props.language.SHEIN_KEY_PWA_16237 || ''
      capitecDialogData.value.emailError = 2
    }
  }
}

const onEmailInputChange = () => {
  if(!capitecDialogData.value.isEmailChange){
    capitecDialogData.value.isEmailChange = true
    triggerNotice({
      id: 'click_input.comp_capitec_dialog',
      data: {
        payment_method: dialogConfig.value.paymentCode,
        bank_code: dialogConfig.value?.bankInfo?.code || '',
        input_type: 'email',
      },
    })
  }
}

const validateInput = () => {
  if (dialogConfig.value.needCollectEmail && dialogConfig.value.needCollectPhoneNumber) {
    onPhoneInputBlur()
    onEmailInputBlur()
    return !capitecDialogData.value.phoneError && !capitecDialogData.value.emailError
  } else if (dialogConfig.value.needCollectEmail) {
    onEmailInputBlur()
    return !capitecDialogData.value.emailError
  } else if (dialogConfig.value.needCollectPhoneNumber) {
    onPhoneInputBlur()
    return !capitecDialogData.value.phoneError
  }
}

const submit = async () => {
  let isOk
  if(isMbwayPayment.value){
    isOk = await handleValidateMbwayPhone()
  } else {
    isOk = validateInput()
  }
  triggerNotice({
    id: 'click_confirm.comp_capitec_dialog',
    data: {
      payment_method: dialogConfig.value.paymentCode,
      bank_code: dialogConfig.value?.bankInfo?.code || '',
      result: isOk ? '1' : '0',
      result_reason: genReportConfirmReason(),
      revise: (capitecDialogData.value.isPhoneChange || capitecDialogData.value.isEmailChange) ? '1' : '0',
    },
  })

  if (isOk) {
    const collectInfo: Record<string, string> = {
      phone: capitecDialogData.value.phone,
      email: capitecDialogData.value.email,
    }
    if (isMbwayPayment.value) {
      collectInfo.phone = '+' + phoneCountryCode.value + capitecDialogData.value.phone
      // routepay-mbway 国际电话区号
      collectInfo.phoneCountryCode = phoneCountryCode.value
      // routepay-mbway 本地电话号码
      collectInfo.localPhone = capitecDialogData.value.phone
    }

    closeCapitecDialog()
    if (typeof dialogConfig.value.handleConfirm === 'function') {
      dialogConfig.value.handleConfirm(collectInfo)
    } else {
      emits('submit', {
        paymentCode: dialogConfig.value.paymentCode,
        collectInfo,
      })
    }
    if (dialogConfig.value.needContinuePay) {
      emits('pay-now')
    }
  }
}

const init = () => {
  capitecDialogData.value.isEmailChange = false
  capitecDialogData.value.isPhoneChange = false
  if (!capitecDialogData.value.phone) {
    capitecDialogData.value.phone = props.collectionInfo.pay_phone || ''
  }
  if (!capitecDialogData.value.email) {
    capitecDialogData.value.email = props.collectionInfo.pay_email || ''
  }

  triggerNotice({
    id: 'expose_info_popup.comp_capitec_dialog',
    data: {
      payment_method: dialogConfig.value.paymentCode,
      bank_code: dialogConfig.value?.bankInfo?.code || '',
    },
  })

  nextTick(() => {
    phoneRef.value?.checkStatus?.({
      valid: true,
      message: '',
    })
    emailRef.value?.checkStatus?.({
      valid: true,
      message: '',
    })
  })
}

watch(capitecVisible, (val) => {
  if (val) {
    init()
  }
}, { immediate: true })


</script>

<style lang="less" scoped>
.payment-capitec-dialog {
  /deep/ .sui-dialog__normal {
    width: 290/37.5rem;
    max-height: none;

    .sui-dialog__body {
      overflow: visible;
    }
    .sui-dialog__footer {
      padding-top: 16/37.5rem;
      padding-bottom: 16/37.5rem;
    }
  }
}
.capitec-dialog-container {
  .capitec-dialog-title {
    color: var(---sui_color_gray_dark1, #000);
    text-align: center;
    font-family: 'SF UI Text';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .capitec-dialog-title__special {
    color: #222;
    margin: .2rem 0;
    font-size: 12px;
    text-align: center;
  }
  .capitec-dialog-form {
    margin-top: 20/37.5rem;
    .form-item {
      margin-bottom: 12/37.5rem;

      .form-item__phoneCountryNum {
        position: relative;
      }
    }
    .diy-note {
      margin-top: 4px;
      color: #999;
      font-family: 'SF UI Display';
      font-size: 12/37.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      .endorsement-safety-tips {
        font-size: 12*2/75rem;
        line-height: 14*2/75rem;
        color: #198055;

        .endorsement-safety-tips__icon {
          height: 12*2/75rem;
          vertical-align: middle;
          margin-right: 4*2/75rem;
        }

        .endorsement-safety-tips__text {
          vertical-align: middle;
        }
      }
    }
  }

  .email {
    display: flex;
    border: 1px solid #ccc;
    height: 1.17rem;
    line-height: 1.17rem;
    margin-top: 0.4rem;

    .email_val {
      border: 0;
      margin: 0;
      height: 100%;
      font-weight: 700;
      flex: 1;
      padding-left: 0.1rem;
    }
  }

  .error-tip {
    color: #e64545;
    margin-top: 0.2rem;
    text-align: left;
  }
}
</style>
