<template>
  <div class="payment-card-field">
    <BaseField
      ref="baseFieldRef"
      v-model="fieldValue"
      inputmode="text"
      :placeholder="documentConfig.placeholder"
      :label="documentConfig.title"
      :required="isRequired"
      :show-clear="true"
      :maxlength="maxLength"
      @change="handleValueInput"
      @blur="handleValueBlur"
    />
    <div
      v-show="errorTips"
      class="error-invalid"
    >
      {{ errorTips }}
    </div>
  </div>
</template>

<script setup lang="ts" name="CardCpfField">
import { watch, ref, computed } from 'vue'
import BaseField from './BaseField.vue'
import { template } from '@shein/common-function'
import { cpfValidator } from '../../../../utils/validator'
import { FORMAT_TAX_NUMBER } from '../../../../common/constants'

const emits = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  language: {
    type: Object,
    default: () => ({}),
  },
  required: {
    type: Boolean,
    default: false,
  },
  documentConfig: {
    type: Object,
    default: () => ({
      placeholder: '',
      rule: '',
      title: '',
    }),
  },
  paymentCode: { // 真实路由到的支付方式
    type: String,
    default: '',
  },
})


const baseFieldRef = ref()

const fieldValue = ref(props.modelValue)

const errorTips = ref('')
const maxLength = ref(99)

watch(() => props.modelValue, val => {
  fieldValue.value = val
})

const isRequired = computed(() => !!cpfValidator({ cpf: '', rule: props.documentConfig.rule }))

// ebbc时格式化税号
const formatCpf = evt => {
  if (!FORMAT_TAX_NUMBER.includes(props.paymentCode)) {
    return evt?.target?.value
  }
  const filterVal = evt?.target?.value.replace(/[^0-9Kk]/g, '')
  let splitMap = [
    { cursor: 1, split: '.' },
    { cursor: 4, split: '.' },
    { cursor: 7, split: '-' },
  ]
  if (filterVal.length === 9) {
    splitMap.forEach(v => v.cursor += 1)
  }
  return filterVal.split('').map((v, i) => {
    const matchSplit = splitMap.find(v => v.cursor === i)
    return `${matchSplit?.split || ''}${v}`
  }).join('')
}

const handleValueInput = ev => {
  fieldValue.value = ev.target.value = formatCpf(ev)
  emits('update:modelValue', fieldValue.value)
}

const handleValueBlur = () => {
  validator()
  emits('update:modelValue', props?.required ? fieldValue.value : '')
}

const validator = () => {
  if (!props?.required) {
    errorTips.value = ''
    return {
      result: true,
      failType: null,
      failReason: null,
    }
  }
  const cpf = fieldValue.value?.trim?.() || ''
  const valid = cpfValidator({ cpf, rule: props.documentConfig.rule })
  errorTips.value = !valid ? '' : template(props.documentConfig.title, props.language?.SHEIN_KEY_PWA_32151)
  return {
    result: !valid,
    failType: valid,
    failReason: null,
    reasonType: 'cpf',
  }
}

const reset = () => {
  fieldValue.value = ''
  errorTips.value = ''
}

defineExpose({
  validator,
  reset,
  focus: () => baseFieldRef.value.focus(),
})

</script>

<style lang="less" scoped>
.font-dpr(@font-size) {
    font-size: @font-size * 0.5;
}

.payment-card-field {
  padding: 24/75rem;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 24/75rem;
    right: 0;
    height: 1px;
    background-color: var(--sui_color_gray_light2, #e5e5e5);
  }
  &.no-underline {
    &:after {
      display: none;
    }
  }
  .error-invalid {
    font-size: 12px;
    line-height: 1;
    color: var(--sui_color_promo, #FA6338);
    margin-top: 8/75rem;
    transition: height 0.8s;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
